import * as CONST from "./constant";

export function loadIntegrationData(data) {
  return {
    type: CONST.LOAD_INTEGRATION_DATA,
    payload: data,
  };
}

export function updateIntegrationData(newData) {
  return {
    type: CONST.UPDATE_INTEGRATION_DATA,
    payload: newData,
  };
}
