import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import { Divider, ListItemButton, ListItemText } from "@mui/material";
import { useSelector } from "react-redux";
import { TENANT_STATUS } from "utils/app/appConstants";

import styles from "./styles";

const { ENABLED } = TENANT_STATUS;
export function AccountsSection({
  tenantsList,
  currentAccount,
  tenant_status,
  handleClose,
  setOpenModal,
}) {
  const identity = useSelector((state) => state.get("userIdentity"));

  const { isDemo } = identity;
  const { demoEnabled } = isDemo;

  return (
    <>
      {tenantsList.map((account, indx) =>
        account.id !== currentAccount ? (
          <ListItemButton
            onClick={(event) => {
              const { origin, href } = window.location;
              const [, fullPath] = href.split(origin);
              const [, hash] = fullPath.split("/#/");
              window.history.pushState(null, "App", `/u/${indx}/#/${hash}`);
              window.location.reload();
            }}
            key={account.id}
          >
            <ListItemText
              primary={
                <span style={styles.accountStyle}>
                  <span className="body2">{account.slug_name}</span>
                </span>
              }
            />
          </ListItemButton>
        ) : null
      )}

      {tenant_status === ENABLED && !demoEnabled && (
        <>
          <ListItemButton
            dense
            onClick={(event) => {
              handleClose(true);
              setOpenModal(true);
            }}
          >
            <ListItemText
              primary={
                <span style={styles.newAccount}>
                  <AddCircleOutline sx={styles.newAccountIcon} />
                  <span className="body1">Crear cuenta adicional</span>
                </span>
              }
            />
          </ListItemButton>
          <Divider component="li" />
        </>
      )}
    </>
  );
}
