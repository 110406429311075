const styles = {
  dialogTitle: {
    color: "var(--blue-gray-5)",
    display: "flex",
    flexDirection: "column",
  },
  titleSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  subtitle: { width: "100%", marginTop: "0.5em" },
  closeIcon: { cursor: "pointer" },
  content: {
    background: "#FFFFFF",
    borderRadius: 4,
    padding: "10px",
    minWidth: "30%",
  },
};
export default styles;
