import * as CONST from "./constant";

export function loadIntegratedAccounts(data) {
  return {
    type: CONST.LOAD_INTEGRATED_ACCOUNTS,
    payload: data,
  };
}

export function updateIntegratedAccounts(newData) {
  return {
    type: CONST.UPDATE_INTEGRATED_ACCOUNTS,
    payload: newData,
  };
}

export function updateIntegratedAccountsStatus(newStatus) {
  return {
    type: CONST.UPDATE_INTEGRATED_ACCOUNT_STATUS,
    payload: newStatus,
  };
}
