import React from "react";
import { isMobile } from "react-device-detect";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import createTheme from "@mui/material/styles/createTheme";
import { makeStyles, withStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import styles from "./styles";

const RedCircularProgress = withStyles({
  root: {
    color: "#f22c36",
  },
})(CircularProgress);

const GrayCircularProgress = withStyles({
  root: {
    color: "#666666",
  },
})(CircularProgress);

const WhiteCircularProgress = withStyles({
  root: {
    color: "white",
  },
})(CircularProgress);

const useStyles = makeStyles((theme) => ({
  ...styles(theme, isMobile),
}));

const renderProgress = (type, msg, classes) => {
  switch (type) {
    case "blocker":
      return (
        <div className={classes.blockerContainer}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RedCircularProgress size={60} thickness={3} />
            {msg && msg !== "" && (
              <span className={classes.message}>{msg}</span>
            )}
          </div>
        </div>
      );
    case "chart":
      return (
        <div className={classes.chartProgressContainer}>
          <GrayCircularProgress thickness={2} />
          {msg && msg !== "" && (
            <span
              className={`${classes.message} ${classes.chartProgressContainer}`}
            >
              {msg}
            </span>
          )}
        </div>
      );
    case "card":
      return (
        <div className={classes.cardProgressContainer}>
          {msg && msg !== "" && (
            <div
              style={{
                textAlign: "center",
                fontFamily: "Lato",
                paddingBottom: 10,
              }}
            >
              <span>{msg}</span>
            </div>
          )}
          <RedCircularProgress thickness={2} />
        </div>
      );
    default:
      return <WhiteCircularProgress />;
  }
};

const Progress = (props) => {
  const { type, msg } = props;
  const classes = useStyles();
  return (
    <ThemeProvider theme={createTheme()}>
      {renderProgress(type, msg, classes)}
    </ThemeProvider>
  );
};

export default Progress;
