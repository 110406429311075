import { Skeleton } from "@mui/material";
import { uniqueId } from "utils/utils";

export function AppMenuSkeleton() {
  return (
    <>
      {Array.from(new Array(5)).map((_, index) => (
        <Skeleton
          key={uniqueId()}
          variant="text"
          width={80}
          height={20}
          sx={{ margin: "0px 0px 0px 2.3em" }}
        />
      ))}
    </>
  );
}
