import * as CONST from "./constant";

const initialState = {};

function reducer(data = initialState, { type, payload }) {
  switch (type) {
    case CONST.LOAD_INTEGRATION_VALIDATION_DATA:
      return payload;
    case CONST.UPDATE_INTEGRATION_VALIDATION_DATA:
      return { ...data, ...payload };
    case CONST.REMOVE_INTEGRATION_VALIDATION_DATA:
      const newData = { ...data };
      delete newData[payload];
      return newData;
    default:
      return data;
  }
}

export default reducer;
