import * as CONST from "./constant";

const initialState = {
  hasAnyIntegration: true,
  recommendedIntegrationAvailable: true,
  showIntegrationsAlert: false,
  integrationsCount: -1,
  allRecommendedIntegrationAvailable: true,
  allRecommendedIntegrationAvailablOK: true,
  recommendedIntegrationAvailableOK: true,
  hasAnySourcesOK: true,
  hasSourcesWithOutCredentials: false,
};

function reducer(data = initialState, { type, payload }) {
  switch (type) {
    case CONST.LOAD_INTEGRATION_DATA:
      const newData = {
        ...payload,
        //hasAnyIntegration: hasAnySourceIntegrated(payload).hasAnyIntegration,
        //recommendedIntegrationAvailable,
        //integrationsCount: hasAnySourceIntegrated(payload).integrationsCount,
        //showIntegrationsAlert: !data?.recommendedIntegrationAvailable,
      };
      return newData;
    case CONST.UPDATE_INTEGRATION_DATA:
      return {
        ...data,
        ...payload,
        //hasAnyIntegration: hasAnySourceIntegrated(payload).hasAnyIntegration,
        //integrationsCount: hasAnySourceIntegrated(payload).integrationsCount,
        //recommendedIntegrationAvailable,
      };
    default:
      return data;
  }
}

const hasAnySourceIntegrated = (integrationsStatus = {}) => {
  const sources = Object.entries(integrationsStatus).filter(
    ([key, value]) => key.includes("has_") && value === true
  );
  //const anyIntegration = sources.filter((source) => source[1] === true);
  const recommendedIntegrationAvailable = sources.some(
    (source) =>
      source[0] === "has_mercadolibre" || source[0] === "has_newfalabella"
  );

  return {
    hasAnyIntegration: sources.length ? true : false,
    integrationsCount: sources.length,
    //recommendedIntegrationAvailable,
  };
};

export default reducer;
