import Livez from "components/Livez/Livez";
import Auth0Login from "containers/Login/Auth0Login";
import Auth0Signup from "containers/Login/Auth0Signup";
import App from "./App";

const AppMiddleware = (props) => {
  const path = window.location;

  if (path.hash === "#/livez") return <Livez />;

  if (path.hash === "#/login") return <Auth0Login />;

  if (path.hash === "#/signup") return <Auth0Signup />;

  return <App {...props} />;
};

export default AppMiddleware;
