const rateSales = (total, value) => {
  return (value * 100) / total;
};
const rateUnits = (total, value) => {
  return (value * 100) / total;
};
const rateOrders = (total, value) => {
  return (value * 100) / total;
};

const CalculatedMeasures = {
  rateSales,
  rateUnits,
  rateOrders,
};

export const getCalculatedMeasures = (measures) => {
  return measures.filter((measure) => CalculatedMeasures[measure.name]);
};

export const addDataCalculatedMeasures = (schema, data, measures, totals) => {
  return data.map((row) => {
    let obj = {};
    measures.forEach((measure) => {
      const { name } = measure;
      const measureName = name.split("rate")[1].toLowerCase();
      const fullMetric = `${schema}.${measureName}`;
      const params1 = totals[0][fullMetric];
      const params2 = row[fullMetric];
      const measureValue = CalculatedMeasures[name](params1, params2);
      const key = `${schema}.${name}`;
      return (obj[key] = measureValue);
    });
    return { ...row, ...obj };
  });
};

export default CalculatedMeasures;
