import { useState } from "react";

import PersonAdd from "@mui/icons-material/PersonAdd";
import { Box } from "@mui/system";
import { IconButton } from "@mui/material";
import { useSelector } from "react-redux";

import { localization } from "localization/es";
import InviteUser from "components/InviteUsers/InviteUser";
import Toast from "components/Toast";
import styles from "./styles";
import WivoButton from "components/WivoButton/Button";

export function InviteUsers() {
  const [openInvitation, setOpenInvitation] = useState(false);
  const identity = useSelector((state) => state.get("userIdentity"));

  const [toast, setToast] = useState({
    show: false,
    type: "success",
    title: "",
    text: "",
  });

  const { accountData, userData } = identity;
  const client = accountData?.client;
  const tenant = accountData?.tenant;
  const name = userData?.name;

  const {
    APP: { INVITE },
  } = localization;

  const closeToast = () => {
    setToast({ show: false, type: "success", title: "", text: "" });
  };

  const handleClickOpenInvitation = () => {
    setOpenInvitation(true);
  };

  const handleCloseInvitation = () => {
    setOpenInvitation(false);
  };

  const handleToast = (type, title, text) => {
    setToast({ show: true, type, title, text });
  };

  const handleInvitationSuccess = () => {
    const msg = "La invitacion ha sido enviada satisfactoriamente.";
    handleToast("success", "Invitación enviada", msg);
  };

  const handleInvitationsFail = () => {
    const msg =
      "La invitación ha fallado. Asegúrese de que el usuario no haya sido invitado ya, o comuníquese con el equipo de soporte.";
    handleToast("error", "Oops! lo sentimos", msg);
  };
  return (
    <>
      <Box
        id="invite_user"
        data-intercom-target="invite_user"
        sx={styles.inviteUserRoot}
        onClick={() => handleClickOpenInvitation()}
      >
        <WivoButton
          text
          label={INVITE}
          startIcon={<PersonAdd />}
          sx={styles.inviteButton}
        />

        <IconButton sx={styles.inviteIcon} component="label">
          <PersonAdd />
        </IconButton>
      </Box>
      {openInvitation && (
        <InviteUser
          open={openInvitation}
          handleClose={handleCloseInvitation}
          tenant_name={client}
          tenant={tenant}
          userName={name}
          invitationSuccess={handleInvitationSuccess}
          invitationsFail={handleInvitationsFail}
        />
      )}
      <Toast show={toast.show} close={closeToast} data={toast} />
    </>
  );
}
