import { DEFAULT_ORDERS_SEARCH } from "containers/FiltersPanel/FilterSearch";
import { filterFiltersByDimension, getTimeDimension } from "utils/utils";

export const ordersQuery = (config) => {
  const query = {
    order: { "Ordersbyitem.purchaseDate": "desc" },
    total: true,
    measures: [
      "Ordersbyitem.units",
      "Ordersbyitem.sales",
      "Ordersbyitem.rateExpenses", // -> % costo marketplace
      "Ordersbyitem.expenses", // -> costo total
    ],
    timeDimensions: [{ ...getTimeDimension(config)[0] }],
    dimensions: [
      "Source.name",
      "Ordersbyitem.sourceType",
      "Ordersbyitem.orderId",
      "Ordersbyitem.purchaseDate.day",
      "Ordersbyitem.itemStatus",
      "Ordersbyitem.logisticType",
      "Ordersbyitem.paymentStatus",
    ],
  };
  return query;
};

export const PUBLICATIONS_HIDDEN_COLUMNS = {
  "Source.name": false,
  "ShipmentStatus.name": false,
  "LogisticDetail.name": false, //- pendiente a quitar
  "Ordersbyitem.itemStatus": false,
  "Ordersbyitem.paymentId": false,
  "ItemStatus.name": false,
  "SourceType.name": false,
  "Ordersbyitem.itemType": false,
  "Ordersbyitem.prodCostStatus": false,
  "Ordersbyitem.logisticType": false,
  "Ordersbyitem.shipmentCosts": false,
  "Ordersbyitem.sellerPayment": false,
  "Ordersbyitem.prodCost": false,
  "Ordersbyitem.renTotal": false,
  "Ordersbyitem.costTotal": false,
  "Ordersbyitem.expenses": false,
  "Ordersbyitem.feesCost": false,
};

export const unprofitable = {
  measures: ["Ordersbyitem.avgSellerPayment"],
  dimensions: [
    "Ordersbyitem.source",
    "Ordersbyitem.orderId",
    "Ordersbyitem.itemStatus",
    "Ordersbyitem.paymentStatus",
    //"Ordersbyitem.prodCostStatus",
  ],
  timeDimensions: [
    {
      dimension: "Ordersbyitem.purchaseDate",
    },
  ],
};

export const highShippingCost = {
  measures: ["Ordersbyitem.shipmentCosts"],
  dimensions: [
    "Ordersbyitem.source",
    "Ordersbyitem.orderId",
    "Ordersbyitem.itemStatus",
    "Ordersbyitem.paymentStatus",
    //"Ordersbyitem.prodCostStatus",
  ],
  timeDimensions: [
    {
      dimension: "Ordersbyitem.purchaseDate",
    },
  ],
};

export const highCommissions = {
  measures: ["Ordersbyitem.rateFeesCost"],
  dimensions: [
    "Ordersbyitem.source",
    "Ordersbyitem.orderId",
    "Ordersbyitem.itemStatus",
    "Ordersbyitem.paymentStatus",
    //"Ordersbyitem.prodCostStatus",
  ],
  timeDimensions: [
    {
      dimension: "Ordersbyitem.purchaseDate",
    },
  ],
};

export const justPaid = {
  measures: [
    "Ordersbyitem.avgSellerPayment",
    "Ordersbyitem.avgRentTotal",
    "Ordersbyitem.shipmentCosts",
    "Ordersbyitem.rateFeesCost",
  ],
  dimensions: [
    "Ordersbyitem.sourceOrder",
    "Ordersbyitem.paymentStatus",
    "Ordersbyitem.itemStatus",
    //"Ordersbyitem.prodCostStatus",
  ],
  timeDimensions: [
    {
      dimension: "Ordersbyitem.purchaseDate",
    },
  ],
};

export const noPayments30d = {
  measures: [
    "Ordersbyitem.avgSellerPayment",
    "Ordersbyitem.avgRentTotal",
    "Ordersbyitem.shipmentCosts",
    "Ordersbyitem.rateFeesCost",
  ],
  dimensions: [
    "Ordersbyitem.sourceOrder",
    "Ordersbyitem.paymentStatus",
    "Ordersbyitem.itemStatus",
    //"Ordersbyitem.prodCostStatus",
  ],
  timeDimensions: [
    {
      dimension: "Ordersbyitem.purchaseDate",
    },
  ],
};

export const paymentStatus = {
  measures: [
    "Ordersbyitem.avgSellerPayment",
    "Ordersbyitem.avgRentTotal",
    "Ordersbyitem.shipmentCosts",
    "Ordersbyitem.rateFeesCost",
  ],
  dimensions: [
    "Ordersbyitem.sourceOrder",
    "Ordersbyitem.paymentStatus",
    "Ordersbyitem.itemStatus",
    //"Ordersbyitem.prodCostStatus",
  ],
  timeDimensions: [
    {
      dimension: "Ordersbyitem.purchaseDate",
    },
  ],
};

export const paymentStatusAvailable = {
  measures: [
    "Ordersbyitem.avgSellerPayment",
    "Ordersbyitem.avgRentTotal",
    "Ordersbyitem.shipmentCosts",
    "Ordersbyitem.rateFeesCost",
  ],
  dimensions: [
    "Ordersbyitem.sourceOrder",
    "Ordersbyitem.paymentStatus",
    "Ordersbyitem.itemStatus",
    //"Ordersbyitem.prodCostStatus",
  ],
  timeDimensions: [
    {
      dimension: "Ordersbyitem.purchaseDate",
    },
  ],
};

export const statusQueryFilters = {
  measures: [
    "Ordersbyitem.orders",
    "Ordersbyitem.avgSellerPayment",
    "Ordersbyitem.avgRentTotal",
    "Ordersbyitem.shipmentCosts",
    "Ordersbyitem.rateFeesCost",
    "Ordersbyitem.justPaidLast7Days",
    "Ordersbyitem.notPaidInMore30d",
  ],
  dimensions: [
    "Ordersbyitem.paymentStatus",
    "Ordersbyitem.itemStatus",
    "Ordersbyitem.sourceType",
    "Ordersbyitem.logisticType",
    //"Ordersbyitem.prodCostStatus",
  ],
  timeDimensions: [
    {
      dimension: "Ordersbyitem.purchaseDate",
    },
  ],
  filters: [
    {
      member: "Ordersbyitem.itemType",
      operator: "equals",
      values: ["Orden"],
    },
  ],
};
export const itemStatus = {
  measures: [
    "Ordersbyitem.avgSellerPayment",
    "Ordersbyitem.avgRentTotal",
    "Ordersbyitem.shipmentCosts",
    "Ordersbyitem.rateFeesCost",
  ],
  dimensions: [
    "Ordersbyitem.itemStatus",
    "Ordersbyitem.sourceOrder",
    "Ordersbyitem.paymentStatus",
    //"Ordersbyitem.prodCostStatus",
  ],
  timeDimensions: [
    {
      dimension: "Ordersbyitem.purchaseDate",
    },
  ],
};

export const logisticStatus = {
  measures: [
    "Ordersbyitem.avgSellerPayment",
    "Ordersbyitem.avgRentTotal",
    "Ordersbyitem.shipmentCosts",
    "Ordersbyitem.rateFeesCost",
  ],
  dimensions: [
    "Ordersbyitem.sourceOrder",
    "Ordersbyitem.itemStatus",
    "Ordersbyitem.paymentStatus",
    //"Ordersbyitem.prodCostStatus",
  ],
  timeDimensions: [
    {
      dimension: "Ordersbyitem.purchaseDate",
    },
  ],
};

export const shippingType = {
  measures: ["Ordersbyitem.orders"],
  timeDimensions: [
    {
      dimension: "Ordersbyitem.purchaseDate",
    },
  ],
};

export const orderStatus = {
  measures: ["Ordersbyitem.orders"],
  timeDimensions: [
    {
      dimension: "Ordersbyitem.purchaseDate",
    },
  ],
};

export const marketplaces = {
  measures: [
    "Ordersbyitem.orders",
    "Ordersbyitem.avgSellerPayment",
    "Ordersbyitem.avgRentTotal",
    "Ordersbyitem.shipmentCosts",
    "Ordersbyitem.rateFeesCost",
  ],
  dimensions: [
    "SourceType.name",
    "Ordersbyitem.sourceOrder",
    "Ordersbyitem.itemStatus",
    "Ordersbyitem.paymentStatus",
    //"Ordersbyitem.prodCostStatus",
  ],
  timeDimensions: [
    {
      dimension: "Ordersbyitem.purchaseDate",
    },
  ],
};

export const products = (config, filtersItem) => {
  const { filters, templateDimensions = [] } = config;
  const dimensionFilters = filterFiltersByDimension(filters, [
    ...templateDimensions,
    ...Object.keys(DEFAULT_ORDERS_SEARCH).map((key) => ({ name: key })),
  ]);
  const { orderId, paymentStatus, itemStatus, productCost } = filtersItem;
  return {
    measures: [
      "Ordersbyitem.units",
      "Ordersbyitem.sales",
      "Ordersbyitem.price",
    ],
    dimensions: [
      "Product.name",
      "Product.category",
      "Product.codproduct",
      "Product.publishingIdentifier",
      //"Publishingsubscriptions.priceTodaySQ",
      "Ordersbyitem.orderId",
      //"Ordersbyitem.prodCostStatus",
    ],
    timeDimensions: [
      {
        dimension: "Ordersbyitem.purchaseDate",
      },
    ],
    filters: [
      ...dimensionFilters,
      {
        member: "Ordersbyitem.orderId",
        operator: "equals",
        values: [orderId],
      },
      {
        member: "Ordersbyitem.paymentStatus",
        operator: "equals",
        values: [paymentStatus],
      },
      {
        member: "Ordersbyitem.itemStatus",
        operator: "equals",
        values: [itemStatus],
      },
      /*{
        member: "Ordersbyitem.prodCostStatus",
        operator: "equals",
        values: [productCost],
      },*/
    ],
    order: { "Ordersbyitem.sales": "desc" },
    //limit: 10,
  };
};

export const orderDataQuery = (config, orderId) => ({
  dimensions: [
    "Ordersbyitem.sourceType",
    "Source.name",
    "Ordersbyitem.suborderId",
  ],
  timeDimensions: [{ ...getTimeDimension(config)[0] }],
  filters: [
    {
      member: "Ordersbyitem.orderId",
      operator: "equals",
      values: [orderId],
    },
  ],
});

export const orderIndicatorQuery = (config, filtersItem = {}) => {
  const { orderId, paymentStatus, itemStatus } = filtersItem;

  return {
    measures: [
      "Ordersbyitem.units",
      "Ordersbyitem.sales",
      "Ordersbyitem.expenses",
      "Ordersbyitem.sellerPayment",
      "Ordersbyitem.avgSellerPayment",
      "Ordersbyitem.prodCost",
      "Ordersbyitem.costTotal", // -> Costo total
      "Ordersbyitem.renTotal", // -> Rentabilidad total
      "Ordersbyitem.avgRentTotal", // -> % Rentabilidad total
    ],
    dimensions: ["Ordersbyitem.orderId", "Ordersbyitem.paymentStatus"],
    timeDimensions: [{ ...getTimeDimension(config)[0] }],
    filters: [
      {
        member: "Ordersbyitem.orderId",
        operator: "equals",
        values: [orderId],
      },
      {
        member: "Ordersbyitem.paymentStatus",
        operator: "equals",
        values: [paymentStatus],
      },
      {
        member: "Ordersbyitem.itemStatus",
        operator: "equals",
        values: [itemStatus],
      },
    ],
  };
};

export const orderDetailQuery = (config, filtersItem) => {
  const { orderId, paymentStatus, itemStatus } = filtersItem;

  return {
    dimensions: [
      "Ordersbyitem.purchaseDate",
      "Ordersbyitem.itemStatus",
      "Ordersbyitem.paymentStatus",
      "Ordersbyitem.paymentId",
      "Ordersbyitem.logisticType",
      "ShipmentStatus.name",
      "Shopper.name",
      "Ordersbyitem.packId",
      //"Orders.shopper_address",
      "Ordersbyitem.logisticDetail",
    ],
    filters: [
      {
        member: "Ordersbyitem.orderId",
        operator: "equals",
        values: [orderId],
      },
      {
        member: "Ordersbyitem.paymentStatus",
        operator: "equals",
        values: [paymentStatus],
      },
      {
        member: "Ordersbyitem.itemStatus",
        operator: "equals",
        values: [itemStatus],
      },
    ],
    timeDimensions: [{ ...getTimeDimension(config)[0] }],
  };
};

export const profitability = {
  measures: ["Ordersbyitem.avgRentTotal"],
  dimensions: [
    "Ordersbyitem.sourceOrder",
    "Ordersbyitem.itemStatus",
    "Ordersbyitem.paymentStatus",
    //"Ordersbyitem.prodCostStatus",
  ],
  timeDimensions: [
    {
      dimension: "Ordersbyitem.purchaseDate",
    },
  ],
};

export const HIDE_PRODUCTS_COLUMNS = {
  "Product.name": true,
  "Product.category": false,
  "Product.codproduct": false,
  "Product.publishingIdentifier": false,
  "Ordersbyitem.price": true,
  "Ordersbyitem.orderId": false,
  "Ordersbyitem.units": true,
  "Ordersbyitem.sales": true,
  "Ordersbyitem.prodCost": true,
  "Ordersbyitem.prodCostStatus": false,
};
