import { LOCATION_CHANGE } from "react-router-redux";
import * as CONST from "./constant";

export function loadAppState(config) {
  return {
    type: CONST.LOAD_APP_STATE,
    payload: config,
  };
}

export function updateAppState(newState) {
  return {
    type: CONST.UPDATE_APP_STATE,
    payload: newState,
  };
}

export function updateQueryParams(payload) {
  return {
    type: LOCATION_CHANGE,
    payload,
  };
}
