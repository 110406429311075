import { Divider, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";

export function LightTooltipContent({ title, description }) {
  return (
    <>
      <span style={{ fontWeight: 800 }}>{title}</span>
      <br />
      <Divider light style={{ margin: "0.3rem 0px 0.3rem 0px" }} />
      <span style={{ fontWeight: 400 }}>{description}</span>
    </>
  );
}

export const LightTooltip = styled(
  ({ className, interactive = false, width = "auto", ...props }) => (
    <Tooltip
      disableInteractive={!interactive}
      {...props}
      classes={{ popper: className }}
    />
  )
)(({ theme, width }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#5B717B",
    boxShadow: theme.shadows[1],
    fontSize: "0.78rem",
    maxWidth: width, // Ajuste de ancho
  },
}));

export default LightTooltip;
