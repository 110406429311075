import React from "react";
import { useRef } from "react";

import Styles from "./styles";

const Content = (props) => {
  const path = window.location.hash;
  const { isReportList, isBannerVisible } = props;
  const styles = Styles(isReportList, isBannerVisible, path);
  const listscroll = useRef();

  return (
    <div className="main-content" ref={listscroll} style={styles.container}>
      <div
        //maxWidth="xl"
        style={{
          //padding:"0 10em",
          height: "100%",
          background: path.includes("/onBoarding/stage")
            ? "#FFF"
            : "var(--blue-gray-1)",
        }}
      >
        {props.children}
      </div>
    </div>
  );
};
export default Content;
