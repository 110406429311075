import SearchBasic from "components/SearchBasic/SearchBasic";

import { localization } from "localization/es";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateReportConfig } from "redux/ReportsConfig/actions";

export const DEFAULT_ORDERS_SEARCH = {
  "Ordersbyitem.orderId": true,
  "Ordersbyitem.packId": true,
  "Ordersbyitem.suborderId": true,
  "Product.name": true,
  "Product.codproduct": true,
  "Variant.name": true,
  "Variant.codvariant": true,
  "Ordersbyitem.publishingIdentifier": true,
};
export function FilterSearch() {
  const reportConfig = useSelector((state) => state.get("reportConfig"));
  const [defaultValue, setDefaulValue] = useState(undefined);
  const dispatch = useDispatch();

  const {
    schema,
    dimensions,
    reportType,
    isSearch,
    searchCriteria,
    loadingConfig,
  } = reportConfig;
  const { SEARCH } = localization;

  useEffect(() => {
    if (!loadingConfig) {
      setDefaulValue(searchCriteria);
    }
  }, [loadingConfig]);

  useEffect(() => {
    if (isSearch) {
      handleSearch(searchCriteria);
    }
  }, [dimensions]);

  const handleSearch = (criteria) => {
    const newFilters = reportConfig.filters.filter((filter) => !filter.or);
    if (criteria && criteria !== "") {
      const searchFilter = { or: [] };
      dimensions.forEach((dimension) => {
        if (
          (reportType !== "orders" && dimension.type === "object") ||
          (reportType === "orders" &&
            !DEFAULT_ORDERS_SEARCH[dimension.name] &&
            dimension.type === "object")
        )
          searchFilter.or.push({
            member: `${dimension.name}`,
            operator: "contains",
            values: [criteria.trim()],
          });
      });
      if (reportType === "orders") {
        Object.keys(DEFAULT_ORDERS_SEARCH).forEach((member) => {
          return searchFilter.or.push({
            member,
            operator: "contains",
            values: [criteria.trim()],
          });
        });
      }

      dispatch(
        updateReportConfig({
          isSearch: true,
          searchCriteria: criteria.trim(),
          filters: [...newFilters, searchFilter],
          timeDimensions: [
            {
              ...reportConfig.timeDimensions[0],
              dimension:
                reportType === "orders"
                  ? "Ordersbyitem.purchaseDate"
                  : schema === "Statements"
                  ? "Statements.statementPaidDate"
                  : "Ordersbyitem.purchaseDate",
            },
          ],
        })
      );
    } else
      dispatch(
        updateReportConfig({
          isSearch: false,
          searchCriteria: undefined,
          filters: [...newFilters],
          timeDimensions: [
            {
              ...reportConfig.timeDimensions[0],
              dimension:
                reportType === "orders"
                  ? "Ordersbyitem.purchaseDate"
                  : schema === "Statements"
                  ? "Statements.statementPaidDate"
                  : "Ordersbyitem.purchaseDate",
            },
          ],
        })
      );
  };

  return (
    <SearchBasic
      defaultCriteria={defaultValue}
      variant="outlined"
      placeholder={SEARCH.DEFAULT_PLACEHOLDER}
      size="small"
      onSearch={handleSearch}
      isMainSearch
      id="main-search"
    />
  );
}
