import * as CONST from "./constant";
import { dateRanges } from "components/NewCalendar/ranges";

const initialState = {
  dashboardList: [],
  reportsList: [],
  download: false,
  isSearch: false,
  searchCriteria: undefined,
  title: "",
  categories: [],
  granularity: "week",
  viewSelected: "dashboard",
  loadingConfig: true,
  loadingRowData: true,
  dashboardMetric: "sales",
  compareWith: "PM",
  compareWithCustom: [
    dateRanges["Last 30 days"].start,
    dateRanges["Last 30 days"].end,
  ],
  schema: "Ordersbyitem",
  reportType: undefined,
  filtersLabels: {},
  dimensions: [
    {
      name: "id",
      type: "string",
      label: "",
      active: true,
    },
  ],
  measures: [
    {
      name: "count",
      type: "number",
      label: "",
      active: true,
    },
  ],
  filters: [],
  quickFilters: {},
  lastFilterSelected: [],
  timeLineFilters: [],
  timeDimensions: [
    {
      dimension: "Ordersbyitem.purchaseDate",
      dateRange: "Last 30 days",
    },
  ],
  filtersDimension: [],
  limit: 500,
  offset: 0,
};

function reducer(config = initialState, { type, payload }) {
  switch (type) {
    case CONST.LOAD_REPORT_CONFIG:
      return payload;
    case CONST.UPDATE_REPORT_CONFIG:
      return { ...config, ...payload };
    default:
      return config;
  }
}

export default reducer;
