import { useEffect, useState } from "react";

import { ListItemButton, ListItemText } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { PLAN_MANAGEMENT, TRIALS, UPGRADE } from "utils/app/appConstants";
import { updateAppState } from "redux/App/actions";
import styles from "./styles";
import moment from "moment";

const { ACTIVATE_TRIAL, UPGRADE_PLAN } = PLAN_MANAGEMENT;

export function TrialSection({ handleClose }) {
  const userIdentity = useSelector((state) => state.get("userIdentity"));
  const [currentPlan, setPlan] = useState("free");
  const dispatch = useDispatch();

  const { accountData } = userIdentity;
  const { trial, plan } = accountData?.properties || {};
  const { type, start_date, duration } =
    (trial && Array.isArray(trial) ? trial[0] : trial) || {};

  const trialAvailable = currentPlan === "free" && !trial;

  useEffect(() => {
    if (trial) {
      let now = moment(new Date());
      let trialStart = moment(start_date, "YYYY-MM-DD”");
      let days = now.diff(trialStart, "days");
      const remainingDays = duration - days;

      if (remainingDays > 0) setPlan(type.toLowerCase());
      else setPlan(plan?.toLowerCase() || "free");
    } else setPlan(plan?.toLowerCase() || "free");
  }, [trial, plan]);

  const handleOnItemClik = (e) => {
    const { title, subtitle, features, cta } = trialAvailable
      ? TRIALS.PRO
      : UPGRADE.PRO;
    const planManagement = {
      action: trialAvailable ? ACTIVATE_TRIAL : UPGRADE_PLAN,
      open: true,
      title,
      subtitle,
      features,
      cta,
    };
    dispatch(updateAppState({ planManagement }));
  };

  return (
    <ListItemButton
      onClick={(event) => {
        handleClose(true);
        handleOnItemClik();
      }}
    >
      <ListItemText
        primary={
          <span style={styles.newAccount}>
            {/*  <AutoFixHighOutlined sx={styles.newAccountIcon} /> */}
            <span className="body1" style={{ fontSize: "0.87em" }}>
              {trialAvailable ? "Activar período de prueba" : "Agendar Reunión"}
            </span>
          </span>
        }
      />
    </ListItemButton>
  );
}
