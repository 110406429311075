import * as CONST from "./constant";
import { PLAN_MANAGEMENT } from "utils/app/appConstants";

const { ACTIVATE_TRIAL } = PLAN_MANAGEMENT;

const initialState = {
  newVersionAvailable: false,
  publishingCount: 0,
  keywordsCount: 0,
  modified: undefined,
  blockedRoute: undefined,
  showRouteBlockedAlert: false,
  goNext: undefined,
  gloablOpenReload: false,
  costStatus: "",
  planManagement: {
    action: ACTIVATE_TRIAL,
    open: false,
    title: "",
    subtitle: "",
    features: [],
    cta: "",
  },
};

function reducer(app = initialState, { type, payload }) {
  switch (type) {
    case CONST.LOAD_APP_STATE:
      return payload;
    case CONST.UPDATE_APP_STATE:
      return { ...app, ...payload };
    default:
      return app;
  }
}

export default reducer;
