import { localization } from "localization/es";
import { DATA_CY } from "./cypressConstants";
import { dateRanges } from "components/NewCalendar/ranges";
import moment from "moment";

const { TOPMENU } = localization;
const { COMPONENTS } = DATA_CY;

export const profitDashboardInitilTimeDimension = () => {
  const range = dateRanges["Last 30 days"];
  return `date=${[
    range.start,
    moment(range.end, "YYYY-MM-DD").subtract(14, "days").format("YYYY-MM-DD"),
  ]}`;
};
const menuItems = {
  /* home: {
    active: false,
    name: TOPMENU.HOME,
    rootPath: "/home",
  }, */
  keywords: {
    active: false,
    name: TOPMENU.COMPETITION,
    rootPath: "/keywords/dashboard/8",
    plan: "free",
    "data-cy": COMPONENTS.navKeywords,
  },
  publications: {
    active: false,
    name: TOPMENU.PUBLISHING,
    //rootPath: `/publications?filters=%5B"Regular"%2C"Con%20Pagos"%2C"1%20Unidad"%5D`,
    rootPath: `/publications?filters=%5B"Regular"%2C"Con%20Pagos"%5D`,
    plan: "free",
    "data-cy": COMPONENTS.navPublications,
  },
  orders: {
    active: false,
    name: TOPMENU.ORDERS,
    rootPath: "/orders",
    plan: "free",
    "data-cy": COMPONENTS.navOrders,
  },
  dashboard: {
    active: false,
    name: TOPMENU.DASHBOARD,
    rootPath: "/dashboards",
    plan: "free",
    "data-cy": COMPONENTS.navDashboard,
    subItems: [
      {
        id: "sales",
        active: false,
        name: "Ventas",
        route: "/sales/dashboard/7",
        plan: "free",
        "data-cy": COMPONENTS.navDashboardSales,
      },
      {
        id: "profitability",
        active: false,
        name: TOPMENU.PROFITABILITY,
        route: `/profitability/dashboard/6?${profitDashboardInitilTimeDimension()}`,
        plan: "pro",
        "data-cy": COMPONENTS.navDashboardProfit,
      },
    ],
  },
  /*  sales: {
    active: false,
    name: TOPMENU.SALES,
    rootPath: "/sales/dashboard/7",
    plan: "free",
  }, */
  reports: {
    active: false,
    name: TOPMENU.REPORTS,
    rootPath: "/reports",
    plan: "starter",
    "data-cy": COMPONENTS.navReports,
  },
  /*  profitability: {
    active: false,
    name: TOPMENU.PROFITABILITY,
    rootPath: "/profitability/dashboard/6",
    plan: "pro",
  }, */
  /*   publications: {
    active: false,
    name: TOPMENU.PUBLICATIONS_MONITOR,
    rootPath: "/publications",
    plan: "free",
  }, */
  /*dashboards: {
    active: false,
    name: TOPMENU.DASHBOARD,
    rootPath: "/dashboards",
    subItems: [],
  },
 reports: {
    active: false,
    name: TOPMENU.REPORTS,
    rootPath: "/reports",
    subItems: [],
  }, */
  /*sources: {
    active: false,
    name: TOPMENU.MARKETPLACE,
    rootPath: "/marketplaces/accounts",
    /*subItems: [
      {
        id: "accounts",
        active: false,
        name: "Integraciones",
        route: "/marketplaces/accounts",
      },
          {
        id: "keywords",
        active: false,
        name: "Keywords",
        route: "/marketplaces/keywords",
      },
      {
        id: "publishing",
        active: false,
        name: "Publicaciones seguidas",
        route: "/marketplaces/publishing",
      }, 
    ],
  }*/
};

export default menuItems;
