import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";

import { ProfileSection } from "../ProfileSection/ProfileSection";
import { Logo } from "components/Logo/Logo";
import { AppMenu } from "../Menu/AppMenu";
import styles from "./styles";
import { useSelector } from "react-redux";
import { TENANT_STATUS } from "utils/app/appConstants";
import { useTheme } from "@mui/styles";
import { useMediaQuery } from "@mui/material";
import { AppMenuSkeleton } from "./AppMenuSkeleton";

export function WivoAppBar(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const userIdentity = useSelector((state) => state.get("userIdentity"));
  const onBoardingState = useSelector((state) => state.get("onBoarding"));

  const { accountData } = userIdentity;
  const tenant_status = accountData?.properties?.tenant_status;
  const onBoardingFinish = onBoardingState?.finish;

  return (
    <AppBar position="static" sx={styles.appBar}>
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Box sx={styles.logoSection}>
            <Logo />
          </Box>
          {!isMobile && (
            <Box sx={styles.mainMenuSection}>
              {!tenant_status && onBoardingFinish && <AppMenuSkeleton />}
              {
                /* tenant_status &&
                tenant_status === TENANT_STATUS.ENABLED && */
                tenant_status && onBoardingFinish && <AppMenu {...props} />
              }
            </Box>
          )}
          <Box sx={styles.profileSection}>
            <ProfileSection />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
