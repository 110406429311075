import * as CONST from "./constant";

const INITIAL_STATE = {
  key: new Date(),
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  //steps: TOUR_STEPS,
};

function reducer(state = INITIAL_STATE, { type, payload = {} }) {
  const { TOUR_STEPS } = payload;
  switch (type) {
    case CONST.START:
      return { ...state, run: true, steps: TOUR_STEPS };
    case CONST.RESET:
      return { ...state, stepIndex: 0 };
    case CONST.STOP:
      return { ...state, run: false };
    case CONST.NEXT_OR_PREV:
      return { ...state, ...payload };
    case CONST.RESTART:
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date(),
        steps: TOUR_STEPS,
      };
    default:
      return state;
  }
}

export default reducer;
