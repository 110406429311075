import { basicHeader, makeRequest } from "services/helper";
import * as REQUEST from "./endpoints";

export function referUsers(users) {
  const options = {
    method: "POST",
    headers: basicHeader,
    body: JSON.stringify(users),
  };
  return makeRequest(REQUEST.referUsersEndPoint(), options);
}
