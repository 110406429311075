import * as CONST from "./constant";

export function loadAppBar(data) {
  return {
    type: CONST.LOAD_APP_BAR,
    payload: data,
  };
}

export function updateAppBar(data) {
  return {
    type: CONST.UPDATE_APP_BAR,
    payload: data,
  };
}

export function clearAppBar() {
  return {
    type: CONST.CLEAR_APP_BAR,
  };
}
