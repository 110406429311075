import { basicHeader, makeRequest } from "services/helper";
import * as REQUEST from "./endpoints";

export function registerKeyword(keyword) {
  const options = {
    method: "POST",
    headers: basicHeader,
    body: JSON.stringify(keyword),
  };
  return makeRequest(REQUEST.keywords(), options);
}

export function getKeywords(queryParams) {
  const options = {
    method: "GET",
    headers: basicHeader,
  };
  return makeRequest(REQUEST.keywords(queryParams), options);
}

export function subscribeToKeyword(subscription) {
  const options = {
    method: "POST",
    headers: basicHeader,
    body: JSON.stringify(subscription),
  };
  return makeRequest(REQUEST.keywordsSubscriptions(), options);
}

export function getKeywordsSubscriptions() {
  const options = {
    method: "GET",
    headers: basicHeader,
  };
  return makeRequest(REQUEST.keywordsSubscriptions(), options);
}

export function getKeywordById(keywordId) {
  const options = {
    method: "GET",
    headers: basicHeader,
  };
  return makeRequest(REQUEST.keywordById(keywordId), options);
}

export function removeKeywordSubscription(id) {
  const options = {
    method: "DELETE",
    headers: basicHeader,
  };
  return makeRequest(REQUEST.removeKeywordSubscriptions(id), options);
}

/* --------------------------- PUBLISHING ENDPOINT ----------------------- */

export function registerPublishing(publishing) {
  const options = {
    method: "POST",
    headers: basicHeader,
    body: JSON.stringify(publishing),
  };
  return makeRequest(REQUEST.findOrCreatePublishing(), options);
}

export function getPublishing() {
  const options = {
    method: "GET",
    headers: basicHeader,
  };
  return makeRequest(REQUEST.publishing(), options);
}

export function removePublishing(id) {
  const options = {
    method: "DELETE",
    headers: basicHeader,
  };
  return makeRequest(REQUEST.removePublishing(id), options);
}

export function subscribeToPublishing(subscription) {
  const options = {
    method: "POST",
    headers: basicHeader,
    body: JSON.stringify(subscription),
  };
  return makeRequest(REQUEST.publishingSubscriptions(), options);
}

export function bulkSubscribeToPublishing(data) {
  const options = {
    method: "POST",
    headers: basicHeader,
    body: JSON.stringify(data),
  };
  return makeRequest(REQUEST.bulkPublishingSubscriptions(), options);
}

export function removePublishingSubscription(id) {
  const options = {
    method: "DELETE",
    headers: basicHeader,
  };
  return makeRequest(REQUEST.removePublishingSubscriptions(id), options);
}

export function getPublishingSubscriptions() {
  const options = {
    method: "GET",
    headers: basicHeader,
  };
  return makeRequest(REQUEST.publishingSubscriptions(), options);
}

export function getPublishingById(publishingId) {
  const options = {
    method: "GET",
    headers: basicHeader,
  };
  return makeRequest(REQUEST.publishingById(publishingId), options);
}

export function getPublishingSeller(url) {
  const options = {
    method: "POST",
    headers: basicHeader,
    body: JSON.stringify(url),
  };
  return makeRequest(REQUEST.publishingSeller(), options);
}

export function updatePublishingMeasuresOwn_publication(id, tenant, pId) {
  const options = {
    method: "PATCH",
    headers: basicHeader,
  };
  return makeRequest(
    REQUEST.updateMeasureOwn_publication(id, tenant, pId),
    options
  );
}

export function updatePublishingSuscriptionOwn_publication(id) {
  const options = {
    method: "PATCH",
    headers: basicHeader,
  };
  return makeRequest(REQUEST.updateSuscriptionOwn_publication(id), options);
}

export function updateKeywordsMeasuresOwn_publication(
  id,
  tenant,
  seller,
  sourcetype
) {
  const options = {
    method: "PATCH",
    headers: basicHeader,
  };
  return makeRequest(
    REQUEST.updateKeywordsMeasureOwn_publication(
      id,
      tenant,
      seller,
      sourcetype
    ),
    options
  );
}

export function updateKeywordsSuscriptionOwn_publication(id) {
  const options = {
    method: "PATCH",
    headers: basicHeader,
  };
  return makeRequest(
    REQUEST.updateKeywordsSuscriptionOwn_publication(id),
    options
  );
}

/* --------------------------- PLAN ENDPOINT ----------------------- */

export function trialRequest(user) {
  const options = {
    method: "POST",
    headers: basicHeader,
    body: JSON.stringify(user),
  };
  return makeRequest(REQUEST.trial(), options);
}

export function planUpgradeRequest(user) {
  const options = {
    method: "POST",
    headers: basicHeader,
    body: JSON.stringify(user),
  };
  return makeRequest(REQUEST.planUpgrade(), options);
}
