import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import Menu from "@mui/icons-material/Menu";
import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";

import styles from "./styles";

export function Profile() {
  const identity = useSelector((state) => state.get("userIdentity"));
  const { accountData } = identity;
  const client = accountData?.client || "";

  return (
    <Box sx={styles.profileRoot}>
      <Typography sx={styles.account} className="body2">
        {client}
      </Typography>
      <IconButton sx={styles.profileArrowIcon}>
        <ArrowDropDown />
      </IconButton>
      <IconButton sx={styles.profileMenuIcon}>
        <Menu />
      </IconButton>
    </Box>
  );
}
