import { forwardRef, useCallback } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useSnackbar, SnackbarContent } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { LinearProgress } from "@mui/material";

const useStyles = makeStyles(() => ({
  root: {
    "@media (min-width:600px)": {
      minWidth: "344px !important",
    },
  },
}));

const SnackBar = forwardRef(({ id, type, title, text, ...props }, ref) => {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  const getType = () => {
    switch (type) {
      case "success":
        return { color: "var(--green-base)", Icon: CheckCircleRoundedIcon };
      case "error":
        return { color: "var(--red-base)", Icon: CancelRoundedIcon };
      case "loader":
        return { color: "none", Icon: null };
      default:
        return { color: "var(--green-base)", Icon: CheckCircleRoundedIcon };
    }
  };
  const { color, Icon } = getType();

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <div
        style={{
          borderRadius: "5px",
          width: "378px",
          padding: type === "loader" ? "15px" : "15px 25px",
          backgroundColor: "var(--gray-90)",
          color: "white",
          borderLeft: `5px solid ${color}`,
          display: "flex",
          //justifyContent: "space-between",
          //alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          {Icon && (
            <Icon style={{ color, marginRight: "15px", marginTop: "0.3em" }} />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              flexGrow: 1,
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {title && (
                <span
                  className="toast-title"
                  style={{
                    fontSize: "1.142rem",
                    margin: "5px 0",
                    color: "#ffffff",
                  }}
                >
                  {title}
                </span>
              )}
            </div>
            {text && (
              <p
                style={{
                  fontSize: "0.78rem",
                  margin: "5px 0",
                  color: "#ffffff",
                }}
              >
                {text}
              </p>
            )}
          </div>
          <CloseIcon
            onClick={handleDismiss}
            style={{
              color: "white",
              cursor: "pointer",
              marginTop: "0.3em",
            }}
          />
        </div>
        {type === "loader" && <LinearProgress style={{ marginTop: "10px" }} />}
      </div>
    </SnackbarContent>
  );
});

SnackBar.displayName = "SnackBar";

export default SnackBar;
