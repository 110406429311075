import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import TableRowsIcon from "@mui/icons-material/TableRows";
import TimelineIcon from "@mui/icons-material/Timeline";

export const ALLOW_PAGE_SIZE = [500, 1000, 10000];
export const REPORTS_TYPES = [
  {
    key: "orders",
    icon: <TableRowsIcon sx={{ color: "#2FBD55" }} />,
    title: "Órdenes",
    subtitle:
      "Genera listas de órdenes filtradas por el criterio que necesites.",
    data_cy: "orders-report",
  },
  {
    key: "summary",
    icon: <ViewColumnIcon sx={{ color: "#FAC802" }} />,
    title: "Resumen",
    subtitle:
      "Analiza todas las métricas de los marketplaces desde un solo lugar. Este reporte es el más usado por los usuarios.",
    data_cy: "summary-report",
  },
  {
    key: "timeline",
    icon: <TimelineIcon sx={{ color: "#3770EF" }} />,
    title: "Tendencias",
    subtitle:
      "Descubre las tendencias de ventas para aprovechar oportunidades y detectar problemas.",
    data_cy: "timeline-report",
  },
];
