import AccessTime from "@mui/icons-material/AccessTime";
import { Box, Stack } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { LIMIT_AFTER_FINISH_TRIAL } from "utils/app/appConstants";

import styles from "./styles";

const TRIAL_STATUS = {
  active: { color: "#2FBD55", background: "#2fbd5524" },
  expiring: { color: "#FF6131", background: "#ff61311c" },
  finish: { color: "#F22C36", background: "#f22c362b" },
};

function getTrialStatus(days) {
  if (days <= 0) return TRIAL_STATUS.finish;
  if (days >= 0 && days <= 5) return TRIAL_STATUS.expiring;
  if (days > 5) return TRIAL_STATUS.active;
}

export function Trials(props) {
  const userIdentity = useSelector((state) => state.get("userIdentity"));
  const [currentStyle, setStyle] = useState(TRIAL_STATUS.active);
  const [remainingDays, setDays] = useState(0);
  const [completionDays, setCompletionDays] = useState(0);

  const { accountData } = userIdentity;
  const { trial } = accountData?.properties || {};
  const {
    type = "free",
    start_date,
    duration,
  } = (trial && Array.isArray(trial) ? trial[0] : trial) || {};

  useEffect(() => {
    if (trial) {
      let now = moment(new Date());
      let trialStart = moment(start_date, "YYYY-MM-DD”");
      let days = now.diff(trialStart, "days");
      const remainingDays = duration - days;

      if (remainingDays >= 0) {
        setDays(remainingDays);
      } else {
        setDays(0);
        setCompletionDays(remainingDays * -1);
      }
      //setDays(remainingDays >= 0 ? remainingDays : 0);
      setStyle(getTrialStatus(remainingDays));
    }
  }, [type, duration]);

  if (trial && completionDays <= LIMIT_AFTER_FINISH_TRIAL)
    return (
      <Box sx={{ ...styles.trialRoot, background: currentStyle.background }}>
        <div style={{ color: currentStyle.color, marginRight: "0.5rem" }}>
          <AccessTime />
        </div>
        <Stack direction="column" sx={{ width: "100%" }}>
          <span className="table-header">PERIODO DE PRUEBA</span>
          <span
            style={{
              color: currentStyle.color,
              fontWeight: 600,
              //lineHeight: "1rem",
              fontSize: "0.6em",
            }}
          >
            {remainingDays > 0 ? `Quedan ${remainingDays} dias` : "Terminado"}
          </span>
        </Stack>
      </Box>
    );
  else return null;
}
