const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  content: {
    maxHeight: "200px",
    overflow: "auto",
    marginTop: "1rem",
    marginBottom: "2rem",
  },
};
export default styles;
