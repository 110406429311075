import { Zoom } from "@mui/material";
import React from "react";

const logo = "/images/logo.svg";

const AppLoading = ({ text = "Cargando..." }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}
  >
    <Zoom in={true}>
      <div>
        <img
          src={logo}
          alt="logo"
          height={56}
          style={{
            animation: "spin 2s linear infinite",
          }}
        />
      </div>
    </Zoom>
    <p className="body2" style={{ fontSize: "0.8rem" }}>
      {text}
    </p>
    <style>{`
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `}</style>
  </div>
);

export default AppLoading;
