/**
 * Create the store with asynchronously loaded reducers
 */

import { createStore, applyMiddleware, compose } from "redux";
import { fromJS, Iterable } from "immutable";
import { routerMiddleware } from "react-router-redux";
import createSagaMiddleware from "redux-saga";
import createReducer from "./reducers";
import { createLogger } from "redux-logger";

const stateTransformer = (state) => {
  if (Iterable.isIterable(state)) return state.toJS();
  else return state;
};

const sagaMiddleware = createSagaMiddleware();
const devtools = window.devToolsExtension || (() => (noop) => noop);
const logger = createLogger({
  stateTransformer,
  collapsed: true,
});

export default function configureStore(initialState = {}, history) {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  if ( process.env.REACT_APP_DEV_MODE === `true`) {
    middlewares.push(logger);
  }

  const enhancers = [applyMiddleware(...middlewares)];

  if ( process.env.REACT_APP_DEV_MODE === "true") {
    enhancers.push(devtools());
  }

  const store = createStore(
    createReducer(),
    fromJS(initialState),
    compose(...enhancers)
  );

  if (window.parent.Cypress) {
    window.appReady = true;
    window.store = store;
  }

  // Create hook for async sagas
  store.runSaga = sagaMiddleware.run;
  store.asyncReducers = {}; // Async reducer registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    import("./reducers").then((reducerModule) => {
      const createReducers = reducerModule.default;
      const nextReducers = createReducers(store.asyncReducers);

      store.replaceReducer(nextReducers);
    });
  }

  return store;
}
