import { useEffect } from "react";

import { hashHistory } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";

import Progress from "components/Progress/Progress";

const isDemo =
   process.env.REACT_APP_DEMO &&
   process.env.REACT_APP_DEMO.toLowerCase() === "true";

const Auth0Login = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  useEffect(() => {
    async function checkUser() {
      if (isAuthenticated) {
        const path = window.location.hash.split("#/")[1];
        await hashHistory.push(path ? path : "/home");
      } else {
        loginWithRedirect();
      }
    }

    if (!isDemo) checkUser(); // called async checkUser()
  }, [isAuthenticated, loginWithRedirect]);

  if (isDemo) {
    hashHistory.push("/home");
    return null;
  }

  return <Progress msg="Cargando..." type="blocker" />;
};

export default Auth0Login;
