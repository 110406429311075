const styles = {
  addReport: {
    width: "100%",
    height: "100%",
    color: "#1976d2",
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: "1em",
  },
};
export default styles;
