import { createTheme } from "@mui/material/styles";

const styles = {
  banner: {
    display: "flex",
    flexDirection: "row",
    margin: 0,
    height: 50,
    background: "linear-gradient(90deg, #ED266E 0%, #FF6131 100%)",
    padding: 12,
    paddingLeft: 20,
    paddingRight: 20,
    justifyContent: "space-between",
    zIndex: 200,
  },
  text: {
    color: "white",
    fontWeight: 600,
    fontSize: "0.9rem",
  },
};

export const uitheme = createTheme({
  palette: {
    primary: { 500: "rgb(242, 44, 54)" },
  },
  fontFamily: "IBM Plex Sans",
});

export const muiTabTheme = createTheme({
  palette: {
    primary: { 500: "rgb(242, 44, 54)" },
  },
  appBar: {
    height: 57,
    color: "#ffffff",
    textColor: "#f22c36",
  },
  fontFamily: "IBM Plex Sans",
  fontSize: "0.9rem",
});

export default styles;
