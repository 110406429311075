/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */

import { combineReducers } from "redux-immutable";
import { fromJS } from "immutable";
import { LOCATION_CHANGE } from "react-router-redux";
import appState from "./redux/App/reducer";
import config from "./redux/ReportsConfig/reducer";
import userIdentity from "./redux/UserIdentity/reducer";
import appBar from "./redux/AppBar/reducer";
import integrationsStatus from "./redux/IntegrationsStatus/reducer";
import integratedAccounts from "./redux/IntegratedAccounts/reducer";
import integration from "./redux/Integration/reducer";
import onBoarding from "./redux/OnBoarding/reducer";
import tour from "./redux/Tours/reducer";
/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@4
 *
 */

// Initial routing state
const routeInitialState = fromJS({
  locationBeforeTransitions: null,
});

/**
 * Merge route into the global application state
 */
function routeReducer(state = routeInitialState, action) {
  switch (action.type) {
    /* istanbul ignore next */
    case LOCATION_CHANGE:
      return state.merge({
        locationBeforeTransitions: action.payload,
      });
    default:
      return state;
  }
}

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
export default function createReducer(asyncReducers) {
  return combineReducers({
    route: routeReducer,
    app: appState,
    reportConfig: config,
    userIdentity: userIdentity,
    appBar: appBar,
    integrationsStatus: integrationsStatus,
    integratedAccounts: integratedAccounts,
    integration: integration,
    onBoarding: onBoarding,
    tour: tour,
    ...asyncReducers,
  });
}
