import { useState, useCallback, useEffect, useMemo } from "react";

import { CubeProvider } from "@cubejs-client/react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import cubejs, { HttpTransport } from "@cubejs-client/core";
import { useDispatch, useSelector } from "react-redux";
import { hashHistory } from "react-router";

import { setUser } from "@sentry/react";
import AppLayout from "components/AppLayout/AppLayout";
import Toast from "components/Toast";
import { CUBEJS_API_URL } from "services/cubejs/constants";
import { updateUserIdentity } from "redux/UserIdentity/actions";
import {
  clearLocalStorage,
  loadAccessToken,
  loadUser,
  saveAccessToken,
  saveUser,
} from "utils/app/localStorage";
import {
  getTenantAddons,
  getUserDataState,
  isNewerVersionAvailable,
  QUICK_FILTERS,
  saveIdentifyData,
  snackbarConfigurations,
  snackbarFillCostConf,
} from "./helper";
import {
  LOGOUT_REDIRECT,
  CHECK_UPDATE,
  CHECK_UPDATE_DAILY,
  TENANT_STATUS,
  PREMIUM_BASE_ROUTES,
  TRIALS,
  UPGRADE,
  TRIAL_RESTART_PERIOD,
} from "utils/app/appConstants";
import { ActivityBasedTimer } from "utils/app/ActivityBasedTimer";
import {
  areArraysEqual,
  isAvailableForPlan,
  registerGroup,
  registerIdentify,
  registerSentryError,
} from "utils/utils";
import { updateAppState } from "redux/App/actions";
import { getAccountData, registerUserDataAut0 } from "services/alpaca/request";
import * as CONST from "redux/UserIdentity/constant";
import { PLAN_MANAGEMENT } from "utils/app/appConstants";
import moment from "moment";
import { useIntegrationData } from "containers/Marketplaces/useIntegrationData";
import { BASIC_SOURCE_DATA } from "containers/Marketplaces/sources/sourcesList";
import {
  removeIntegrationValidationData,
  updateIntegrationValidationData,
} from "redux/Integration/actions";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { updateReportConfig } from "redux/ReportsConfig/actions";
import {
  getFiltersByQuickFilter,
  QUICK_FILTERS_OPTIONS,
} from "containers/Orders/quickFiltersOptions";
import AppLoading from "components/Progress/AppLoading";
import { QUICK_FILTERS_OPTIONS as QUICK_FILTERS_OPTIONS_PUBLICATIONS } from "containers/PublicationsMonitor/quickFiltersOptions";
import { useRef } from "react";
const versioningTimer = new ActivityBasedTimer();

const demoActive = CONST.DEMO;

const { ACTIVATE_TRIAL, UPGRADE_PLAN } = PLAN_MANAGEMENT;

/*const initialAccessToken = loadAccessToken();
const initialTenant = loadUser();
 const initialCubejsApi = cubejs({
  transport: new HttpTransport({
    authorization: `Bearer ${initialAccessToken}`,
    apiUrl: `${CUBEJS_API_URL}`,
    headers: {
      tenant: initialTenant,
    },
  }),
}); */
const App = (props) => {
  const {
    isLoading,
    error,
    isAuthenticated,
    user,
    getAccessTokenSilently,
    logout,
  } = useAuth0();

  const [cubejsApi, setCubejsApi] = useState(null);
  const [open, setOpen] = useState(false);
  const identity = useSelector((state) => state.get("userIdentity"));
  const reportConfig = useSelector((state) => state.get("reportConfig"));
  const integration = useSelector((state) => state.get("integration"));
  const { reloadIntegrationData } = useIntegrationData();
  const integrationsStatus = useSelector((state) =>
    state.get("integrationsStatus")
  );
  const route = useSelector((state) => state.get("route"));
  const app = useSelector((state) => state.get("app"));
  const [currentUser, setCurrentUser] = useState(() =>
    Number(sessionStorage.getItem("currentUser"))
  );
  const [currentPlan, setPlan] = useState(null);
  const dispatch = useDispatch();
  const [remainingDays, setDays] = useState(-1);

  const routeObject = route.get("locationBeforeTransitions");
  const { date, filters: queryFilters } = routeObject?.query;
  const { pathname } = routeObject || { pathname: "" };
  const { filters } = reportConfig;

  const { hasAnyIntegration } = integrationsStatus;

  const { isDemo, userData, accountData, accessToken } = identity;
  const { demoEnabled, tenantDemo } = isDemo;
  const thresholds = accountData?.thresholds;
  const { user_metadata, user_id, tenantsList = [] } = userData || {};
  const claims = process.env.REACT_APP_CLAIMS;
  const {
    newVersionAvailable,
    modified,
    costStatus,
    productsTotal,
    fillCostStatus,
  } = app;

  const { tenant } = accountData;
  const { trial, plan } = accountData?.properties || {};
  const { type, start_date, duration } =
    (trial && Array.isArray(trial) ? trial[0] : trial) || {};

  const fillCostStatusSaved = useMemo(
    () => sessionStorage.getItem("fillCostStatus"),
    []
  );
  const statusCostSaved = useMemo(
    () => sessionStorage.getItem("costStatus"),
    []
  );
  const keyRef = useRef(null);
  const fillCostRef = useRef(null);

  const stimateTime = useMemo(() => {
    // Tamaño del lote
    const batchSize = 5000;

    // Número de lotes
    const numBatches = Math.ceil(productsTotal / batchSize);

    // Supongamos tiempos aproximados
    const timePerBatchQuery = 1.5; // 1 segundo por consulta de lote
    const timePerBatchWrite = 1.5; // 1.5 segundos por escritura de lote

    // Tiempo total estimado
    return Math.ceil(
      (numBatches * timePerBatchQuery + numBatches * timePerBatchWrite) / 60
    );
  }, [productsTotal]);

  useEffect(() => {
    const currentStatus = costStatus || statusCostSaved;
    if (currentStatus) {
      if (currentStatus === "completed" || currentStatus === "error") {
        closeSnackbar(keyRef.current);
        sessionStorage.removeItem("costStatus");
      }
      const range = currentStatus === "loading" ? stimateTime : null;
      const snackBarConfig = snackbarConfigurations(range);
      if (currentStatus in snackBarConfig) {
        keyRef.current = enqueueSnackbar("", snackBarConfig[costStatus]);
      }
    }
  }, [costStatus, statusCostSaved, stimateTime]);

  useEffect(() => {
    const currentStatus = fillCostStatus || fillCostStatusSaved;
    if (currentStatus) {
      if (
        currentStatus === "completed" ||
        currentStatus === "error" ||
        currentStatus === "close"
      ) {
        closeSnackbar(fillCostRef.current);
        sessionStorage.removeItem("fillCostStatus");
      }
      const snackBarConfig = snackbarFillCostConf();
      if (currentStatus in snackBarConfig) {
        fillCostRef.current = enqueueSnackbar(
          "",
          snackBarConfig[fillCostStatus]
        );
      }
    }
  }, [fillCostStatus, fillCostStatusSaved]);

  useEffect(() => {
    if (!date) return;
    const { dimension, dateRange: selectValue } =
      reportConfig.timeDimensions[0];
    const currentDate = date.includes(",") ? date.split(",") : date;
    const isObject = typeof currentDate === "object";
    if (isObject) {
      if (!areArraysEqual(date, selectValue)) {
        const timeDimensions = [{ dimension, dateRange: currentDate }];

        dispatch(updateReportConfig({ timeDimensions }));
      }
    } else {
      if (currentDate !== selectValue) {
        const timeDimensions = [{ dimension, dateRange: currentDate }];

        dispatch(updateReportConfig({ timeDimensions }));
      }
    }
  }, [date]);

  useEffect(() => {
    if (!queryFilters) return;
    if (!filters.length && (thresholds || thresholds === null)) {
      try {
        const FILTERS_OPTIONS = pathname.includes("/orders")
          ? QUICK_FILTERS_OPTIONS(thresholds)
          : QUICK_FILTERS_OPTIONS_PUBLICATIONS(thresholds);
        const decodedFilter = decodeURIComponent(queryFilters);

        const parsedFilters = JSON.parse(decodedFilter) || [];

        const filterArray = [];
        const quickFilters = {};
        parsedFilters.forEach((filter) => {
          if (filter?.or) return;
          if (typeof filter === "object") {
            const { member, operator, values, or } = filter;
            if (!or && (!member || !operator || !values))
              return delete routeObject?.query.filters;
            filterArray.push({ ...filter, values: values.flat(1000) });
          } else {
            const qfilter = getFiltersByQuickFilter(filter, FILTERS_OPTIONS);

            if (qfilter) {
              if (QUICK_FILTERS[filter]) quickFilters[filter] = qfilter;
              else filterArray.push(...qfilter);
            } else delete routeObject?.query.filters;
          }
        });
        if (filterArray.length || Object.keys(quickFilters).length)
          dispatch(updateReportConfig({ filters: filterArray, quickFilters }));
      } catch (error) {
        return delete routeObject?.query.filters;
      }
    }
  }, [queryFilters, thresholds]);

  const handleClose = () => {
    setOpen(false);
    clearLocalStorage();
    logout({ returnTo: LOGOUT_REDIRECT });
  };

  const fireNewVersionAction = (modified) => {
    return dispatch(
      updateAppState({
        newVersionAvailable: true,
        modified: modified,
      })
    );
  };

  const checkforUpdates = () => {
    return isNewerVersionAvailable().then((resp) => {
      const { isNewVersion, modified } = resp;
      if (isNewVersion) {
        fireNewVersionAction(modified);
      }
    });
  };

  /*useEffect(() => {
    if (accessToken) {
      setCubejsApi(
        cubejs({
          transport: new HttpTransport({
            authorization: `Bearer ${accessToken}`,
            apiUrl: `${CUBEJS_API_URL}`,
            headers: {
              tenant: tenantsList
                ? tenantsList[currentUser]?.tenant
                : undefined,
            },
          }),
        })
      );
    }
  }, [userData, currentUser, accessToken, tenantsList]); */

  useEffect(() => {
    if (trial) {
      let now = moment(new Date());
      let trialStart = moment(start_date, "YYYY-MM-DD”");
      let days = now.diff(trialStart, "days");
      const remainingDays = duration - days;
      setDays(remainingDays >= 0 ? remainingDays : 0);

      if (remainingDays > 0) setPlan(type?.toLowerCase() || "pro");
      else setPlan(plan?.toLowerCase() || "free");
    } else {
      setPlan(plan?.toLowerCase() || "free");
      setDays(-1);
    }
  }, [trial, plan]);

  useEffect(() => {
    const checkVersion = versioningTimer.setInterval({
      async callback() {
        const { isNewVersion, modified } = await isNewerVersionAvailable();
        if (!isNewVersion) {
          return;
        }
        fireNewVersionAction(modified);
      },
      interval: CHECK_UPDATE,
      forcedInterval: CHECK_UPDATE_DAILY,
    });
    versioningTimer.runTimersCheck();

    return () => {
      versioningTimer.clearInterval(checkVersion);
    };
  }, []);

  useEffect(() => {
    if (user_id && user_metadata) {
      if (!user_metadata?.origin) {
        const origin = demoEnabled ? "demo" : "app";
        const user_metadata = { origin };
        registerUserDataAut0({
          user_id,
          user_data: { user_metadata },
        })
          .then((resp) => {
            const { status, err } = resp;
            if (err) throw new Error();
            if (status >= 200 && status <= 300) {
              registerIdentify(user_id, { origin, ecommerce_app: true });
              dispatch(
                updateUserIdentity({
                  userData: {
                    ...userData,
                    user_metadata: {
                      ...userData.user_metadata,
                      origin,
                    },
                  },
                })
              );
            }
          })
          .catch((err) => {
            registerSentryError(err?.message ?? `Error al registrar el origin`);
          });
      }
    }
  }, [user_metadata, user_id, demoEnabled]);

  useEffect(() => {
    checkforUpdates();
    versioningTimer.runTimersCheck();
  }, [route, reportConfig]);

  useEffect(() => {
    const { origin, href } = window.location;
    const [, fullPath] = href.split(origin);
    const [, hash] = fullPath.split("/#/");
    const activeUser = Number(fullPath.split("/").at(2)) ?? 0;
    if (routeObject.action === "POP" && currentUser !== activeUser) {
      if (tenantsList?.length)
        if (tenantsList[activeUser]) {
          getAccountData(tenantsList[activeUser]?.tenant)
            .then((resp) => {
              const { err, data } = resp;
              if (err) {
                throw new Error("Error obteniendo el tenant status");
              }
              if (
                data &&
                data?.properties?.tenant_status !== TENANT_STATUS.LOADING
              ) {
                dispatch(
                  updateUserIdentity({
                    accountData: {
                      ...data,
                      client: data.slug_name,
                      addons: getTenantAddons(data?.properties),
                      properties: {
                        ...data.properties,
                        compare_mode:
                          data?.properties?.compare_mode || "standard",
                      },
                    },
                  })
                );
              }
              if (currentUser !== activeUser) {
                sessionStorage.setItem("currentUser", activeUser);
                setCurrentUser(activeUser);
              }
            })
            .catch((err) => {
              registerSentryError(
                err?.message ?? `Error al cargar los datos de la cuenta`
              );
            });
        } else {
          window.history.pushState(null, "App", `/u/0/#/${hash}`);
        }
    }
  }, [route, tenantsList]);

  useEffect(() => {
    let pathRoute = routeObject.pathname;
    if (pathRoute.startsWith("#/")) pathRoute = pathRoute.split("#")[1];
    if (!pathRoute.startsWith("/")) pathRoute = `/${pathRoute}`;

    const baseRoute = pathRoute.split(`/`)[1];
    if (baseRoute !== "home") {
      const isPremiumRoute = PREMIUM_BASE_ROUTES[baseRoute];
      const localPlan = plan?.toLowerCase();

      if (
        plan &&
        ((trial && remainingDays === 0) || !trial) &&
        isPremiumRoute &&
        !isAvailableForPlan(localPlan, PREMIUM_BASE_ROUTES[baseRoute]?.plan)
      ) {
        hashHistory.push("/home");
        let trialAvailable = localPlan === "free" && !trial;
        if (!trialAvailable && currentPlan === "free") {
          let now = moment(new Date());
          let trialStart = moment(start_date, "YYYY-MM-DD”");
          let days = now.diff(trialStart, "days");
          if (days > TRIAL_RESTART_PERIOD) trialAvailable = true;
        }
        const { title, subtitle, features, cta } = trialAvailable
          ? TRIALS.PRO
          : UPGRADE.PRO;
        const planManagement = {
          action: trialAvailable ? ACTIVATE_TRIAL : UPGRADE_PLAN,
          open: true,
          title,
          subtitle,
          features,
          cta,
        };
        dispatch(updateAppState({ planManagement }));
      }
    }
    if (newVersionAvailable) {
      localStorage.setItem("last-updated", modified);
      window.location.reload();
    }
  }, [route, plan, remainingDays, trial, userData]);

  const handleAppBehavior = useCallback(
    async (identity) => {
      const { origin, href } = window.location;
      const [, fullPath] = href.split(origin);
      const activeUser = Number.isNaN(Number(fullPath?.at(3)))
        ? 0
        : Number(fullPath?.at(3));

      let userIdentity = { ...identity };
      let accessToken = {};
      let tenant = demoEnabled ? tenantDemo : undefined;
      let loginsCount = undefined;
      let client = undefined;
      let plan = undefined;
      if (!demoEnabled) {
        if (!userIdentity?.userData?.tenant) {
          accessToken = await getAccessTokenSilently({});
          if (accessToken) {
            saveAccessToken(accessToken);
          } else {
            accessToken = loadAccessToken();
          }
          userIdentity = { ...userIdentity, accessToken };
          const userData = await getUserDataState(user, accessToken);
          userIdentity["userData"] = { ...userData, ...userIdentity.userData };

          const currentAccountData =
            userData.tenantsList[activeUser] ?? userData.tenantsList[0] ?? {};
          client = currentAccountData?.slug_name;
          plan = currentAccountData?.properties?.plan;
          userIdentity["accountData"] = {
            ...currentAccountData,
            client,
            properties: {
              ...currentAccountData.properties,
              compare_mode:
                currentAccountData?.properties?.compare_mode || "standard",
            },
          };
        }
        tenant =
          userIdentity?.userData?.tenantsList[activeUser]?.tenant ??
          userIdentity.userData.tenant ??
          userIdentity?.accountData?.tenant;
        loginsCount = userIdentity.userData.loginsCount;
      } else {
        const { data, status, err } = await getAccountData(tenantDemo, true);
        if (err)
          registerSentryError(`Error al cargar los datos de la cuenta demo`);
        client = data?.slug_name;
        plan = data?.properties?.plan;
        userIdentity["accountData"] =
          {
            ...data,
            client,
            properties: {
              ...data.properties,
              compare_mode: data?.properties?.compare_mode || "standard",
            },
          } || {};
        userIdentity["userData"] = {};
        tenant = tenantDemo;
      }
      let rediretToOnboarding = false;

      const tenantStatus = userIdentity.accountData?.properties?.tenant_status;
      const flow = userIdentity.userData?.user_metadata?.flow;
      if (tenantStatus === TENANT_STATUS.ONBOARDING && !flow) {
        rediretToOnboarding = true;
      }
      if (!tenant) {
        if (!loginsCount) {
          const retries = Number(sessionStorage.getItem("retries")) ?? 0;
          if (retries === 2) {
            sessionStorage.setItem("retries", 0);
            hashHistory.push("/something_has_gone_wrong");
            registerSentryError(`Error al cargar los datos de la cuenta`);
          } else {
            sessionStorage.setItem("retries", retries + 1);
            window.location.reload();
          }
        } else {
          sessionStorage.setItem("retries", 0);
          rediretToOnboarding = true;
        }
      }

      const { userData } = userIdentity;

      setCubejsApi(
        cubejs({
          transport: new HttpTransport({
            authorization: `Bearer ${accessToken}`,
            apiUrl: `${CUBEJS_API_URL}`,
            headers: {
              tenant,
            },
          }),
        })
      );

      dispatch(updateUserIdentity(userIdentity));
      saveIdentifyData(userIdentity);
      setUser({
        id: userData?.sub,
        email: userData?.email,
        username: userData?.name,
        ip_address: "{{auto}}",
      });
      tenant && registerGroup(tenant, { name: client, plan }); //name: Nombre del tenant, es la propiedad que enlaza segment con intercom

      if (rediretToOnboarding) {
        hashHistory.push("/onBoarding/stage/start");
      }
      saveUser(tenant);
    },
    [getAccessTokenSilently, claims, demoEnabled, dispatch, hasAnyIntegration]
  );

  useEffect(() => {
    if (isDemo) {
      handleAppBehavior(identity);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated && !isLoading && !isDemo) {
      handleAppBehavior(identity);
    }
  }, [isAuthenticated, isLoading, handleAppBehavior, isDemo]);

  useEffect(() => {
    if (Object.keys(integration).length && tenant) {
      Object.entries(integration).forEach(([key, data]) => {
        const { informed, status, identifier, action, newIdentifier, source } =
          data;
        const type = status === "OK" ? "success" : "error";
        const title = type === "success" ? "Integración exitosa" : "Error";
        const text =
          type === "success"
            ? `Se ha integrado ${BASIC_SOURCE_DATA[source]?.name} satisfactoriamente`
            : `Ha fallado la integración con ${BASIC_SOURCE_DATA[source]?.name}`;
        if (status !== "VALIDATING" && !informed) {
          if (action === "NEW_ACCOUNT") {
            if (status === "OK") {
              dispatch(
                updateIntegrationValidationData({
                  [newIdentifier]: {
                    ...integration[identifier],
                    identifier: newIdentifier,
                    informed: true,
                  },
                })
              );
            }
            dispatch(removeIntegrationValidationData(identifier));
          } else
            dispatch(
              updateIntegrationValidationData({
                [identifier]: {
                  ...integration[identifier],
                  informed: true,
                },
              })
            );
          reloadIntegrationData(tenant, null, true);
          const toastData = { type, title, text };
          if (status !== "WITH_OUT_CREDENTIALS")
            enqueueSnackbar("", {
              variant: "snackbar",
              ...toastData,
            });
        }
      });
    }
  }, [integration, tenant]);

  if (isLoading) {
    return <AppLoading text="Cargando..." />; //<Progress type="blocker" msg={PROGRESS.MESSAGE + "auth0"} />;
  }

  if (error) {
    const toastData = {
      type: "error",
      title: "Error",
      text: "A ocurrido un error, la aplicación se cerrará pronto",
    };
    registerSentryError(`A ocurrido un error en la autenticación en auth0`);
    return <Toast show={open} close={handleClose} data={toastData} />;
  }

  if (demoEnabled && tenant) {
    const cubejsApi = cubejs({
      transport: new HttpTransport({
        authorization: `Bearer ${accessToken}`,
        apiUrl: `${CUBEJS_API_URL}`,
        headers: {
          tenant: tenantDemo,
        },
      }),
    });
    return (
      <CubeProvider cubejsApi={cubejsApi}>
        <AppLayout {...props} />
      </CubeProvider>
    );
  }

  if (cubejsApi)
    return (
      <CubeProvider cubejsApi={cubejsApi}>
        <AppLayout {...props} />
      </CubeProvider>
    );
  else {
    return <AppLoading text="Cargando..." />; //<Progress type="blocker" msg={PROGRESS.MESSAGE} />;
  }
};
export default demoActive
  ? App
  : withAuthenticationRequired(App, {
      onRedirecting: () => <AppLoading text="Cargando..." />, //<Progress msg="Cargando..." type="blocker" />,
      returnTo: window.location.hash,
    });
