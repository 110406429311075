import { Button } from "@mui/material";
import Add from "@mui/icons-material/Add";

import { localization } from "localization/es";
import styles from "./styles";

export function NewReport({ handleOnClick }) {
  const { APP } = localization;

  return (
    <Button
      variant="text"
      id="create-report-top-menu"
      data-intercom-target="menu-option-create-report"
      startIcon={<Add />}
      sx={styles.addReport}
      onClick={handleOnClick}
    >
      {APP.ADD_REPORT}
    </Button>
  );
}
