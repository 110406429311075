import * as CONST from './constant'

export function loadReportConfig(config) {
    return ({
        type: CONST.LOAD_REPORT_CONFIG,
        payload: config
    })
}

export function updateReportConfig(newconfig) {
    return ({
        type: CONST.UPDATE_REPORT_CONFIG,
        payload: newconfig
    })
}