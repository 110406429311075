import * as CONST from "./constant";

const initialState = {
  isDemo: {
    demoEnabled: CONST.DEMO, //*/ true,
    tenantDemo: CONST.DEV //*/ true
      ? "ed9f6563-c552-4bc5-a3df-989a0348780b" //"c17d52e5-3ee1-4ca2-af57-b925619594df"
      : "6256506b-6699-4e70-a678-eaec89f73246",
    originName: "",
  },
  accessToken: undefined,
  userData: {},
  accountData: {},
};

function reducer(identity = initialState, { type, payload }) {
  switch (type) {
    case CONST.LOAD_USER_IDENTITY:
      return payload;
    case CONST.UPDATE_USER_IDENTITY:
      return { ...identity, ...payload };
    case CONST.CLEAR_USER_IDENTITY:
      return {
        accessToken: undefined,
        userData: {},
      };
    default:
      return identity;
  }
}

export default reducer;
