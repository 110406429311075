const styles = {
  root: {
    ".MuiFormControl-root-MuiTextField-root, .MuiOutlinedInput-root": {
      height: "35px",
    },
    "& .MuiInputBase-input": {
      color: "var(--blue-gray-5)",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      //paddingRight: "8px",
    },
    "& .MuiOutlinedInput-root": {
      // - The Input-root, inside the TextField-root
      "& fieldset": {
        // - The <fieldset> inside the Input-root
        borderColor: "var(--blue-gray-3)", // - Set the Input border
      },
    },
  },
  divider: {
    height: 25,
    margin: 1,
  },
};
export default styles;
