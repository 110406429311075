import { useDispatch, useSelector } from "react-redux";
import { hashHistory } from "react-router";
import { updateReportConfig } from "redux/ReportsConfig/actions";
import { TENANT_STATUS } from "utils/app/appConstants";
import styles from "./styles";

export function Logo() {
  const identity = useSelector((state) => state.get("userIdentity"));
  const onBoardingState = useSelector((state) => state.get("onBoarding"));
  const dispatch = useDispatch();

  //const {
  //  accountData: { properties },
  //} = identity;
  //const status = properties?.tenant_status;
  const onBoardingFinish = onBoardingState?.finish;

  return (
    <div
      data-cy="nav-home"
      style={styles.root}
      onClick={() => {
        dispatch(
          updateReportConfig({
            filters: [],
            quickFilters: {},
            lastFilterSelected: [],
            dashboardMetric: "sales",
            loadingConfig: true,
            offset: 0,
            isSearch: false,
            searchCriteria: undefined,
            reportType: undefined,
            filtersLabels: {},
            filtersKeys:{}

          })
        );
        onBoardingFinish && hashHistory.push("/home");
      }}
    >
      <img src="/images/logo.svg" alt="logo" style={styles.logo} />
    </div>
  );
}
