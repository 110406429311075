export default (theme, isMobile) => ({
    blockerContainer: {
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        top: 0, left: 0, bottom: 0, right: 0,
        position: 'absolute',
        zIndex: 2000,
        paddingTop: '25%'
    },
    message: {
        margin: 20,
        fontFamily: 'Lato',
        color: '#777777',
        fontSize: '0.675rem',
        maxWidth: isMobile ? 170 : 400,
        textAlign: 'center'
    },
    chartProgressContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 30,
        height: '100%'
    },
    chartMessage: {
        color: '#777777'
    },
    cardProgressContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '100px',
        fontSize: '0.619rem',
        paddingTop: 30,
        justifyContent: 'center',
        alignItems: 'center'
    }
})