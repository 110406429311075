import React, { Suspense, useRef } from "react";

import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import {
  Router,
  applyRouterMiddleware,
  browserHistory,
  hashHistory,
} from "react-router";
import { BrowserRouter } from "react-router-dom";
import { syncHistoryWithStore } from "react-router-redux";
import { useScroll } from "react-router-scroll";
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";
import { SnackbarProvider } from "notistack";

import configureStore from "./store";
import createRoutes from "./routes";
import AppMiddleware from "containers/App/AppMiddleware";
import "./index.css";

import * as Sentry from "@sentry/react";
import { LicenseInfo } from "@mui/x-license-pro";
import { useCheckStatus } from "components/CheckStatus/CheckStatus";
import SnackBar from "components/Toast/SnackBar";
import AppLoading from "components/Progress/AppLoading";

LicenseInfo.setLicenseKey(
  //"0bdf6415163f6a52750339717e38ffdaTz00NzQ0NCxFPTE2ODk1MTY0MzkyODAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
  //"e2a4fd2bb464ec433a11c42f6c713fb5Tz03MTg5NSxFPTE3MjI1NDMxODgwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y",
  "5cc473e1b5ae1357f6802e207aa707d3Tz05NTcwMSxFPTE3NTQ1ODUzNjUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI="
);

const selectLocationState = () => {
  let prevRoutingState;
  let prevRoutingStateJS;

  return (state) => {
    const routingState = state.get("route");
    if (!routingState.equals(prevRoutingState)) {
      prevRoutingState = routingState;
      prevRoutingStateJS = routingState.toJS();
    }

    return prevRoutingStateJS;
  };
};

const store = configureStore({}, browserHistory);
const history = syncHistoryWithStore(hashHistory, store, {
  selectLocationState: selectLocationState(),
});

/**
 * Iniciando centry
 */
Sentry.init({
  dsn: "https://71f55f2fa20449369d0fdd0cadee15a2@o113434.ingest.sentry.io/5429701",
  release: "1.0",
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.BrowserTracing(history),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
      networkDetailAllowUrls: [window.location.origin],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  // Cubejs -> https://cube.wivoanalytics.com/cubejs-api/v1/
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/cube\.wivoanalytics\.com\/cubejs-api\/v1/,
  ],

  environment:
    process.env.REACT_APP_DEV_MODE === "true" ? "staging" : "production",
});

const rootRoute = {
  component: AppMiddleware, //App,
  childRoutes: createRoutes(store),
};

export default function Root(props) {
  const currentUser = useRef(sessionStorage.getItem("currentUser"));

  const { status } = useCheckStatus();
  const middleware = applyRouterMiddleware(useScroll());

  if (status?.code === 500) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          color: "red",
        }}
      >
        <h3 id="status">{status?.code}</h3>
        <span id="details">{status?.details}</span>
      </div>
    );
  }

  return (
    <Provider store={store}>
      <Suspense fallback={<AppLoading text="Cargando..." />}>
        <BrowserRouter basename={`u/${currentUser.current ?? 0}`}>
          <Auth0ProviderWithHistory>
            <SnackbarProvider
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              Components={{
                snackbar: SnackBar,
              }}
            >
              <Router
                history={history}
                routes={rootRoute}
                render={middleware}
              />
            </SnackbarProvider>
          </Auth0ProviderWithHistory>
        </BrowserRouter>
      </Suspense>
    </Provider>
  );
}

ReactDOM.render(<Root />, document.getElementById("root"));
