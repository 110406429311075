import { useEffect } from "react";
import { useState } from "react";
import { ENV_VARIABLES } from "utils/app/appConstants";

export function useCheckStatus() {
  const [status, setStatus] = useState({ code: 200, details: `OK` }); //500 for ERROR, 200 for OK

  useEffect(() => {
    let errors = ENV_VARIABLES.filter(
      (variable) => variable.value === undefined
    ).map((variable) => variable.key);

    if (errors && errors.length) {
      setStatus({
        code: 500,
        details: `KODKOD-ERROR: Variable(s) de entorno ${errors} no está(n) definida(s)`,
      });
    }
  }, []);

  return {
    status,
  };
}
