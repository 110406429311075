import { basicHeader, makeRequest } from "services/helper";
import * as REQUEST from "./endpoints";

export function fillCosts(data) {
  const options = {
    method: "POST",
    //headers: {
    //  ...basicHeader,
    //  "Content-type": "multipart/form-data",
    //},
    body: data,
  };
  return makeRequest(REQUEST.fillCostPath(), options);
}
