import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import {
  RECORD_AVAILABLE_BY_PLAN,
  TOOLS_DISABLED_BY_PLAN,
} from "utils/app/appConstants";

import styles from "./styles";

export function PlanSection({ open, currentPlan, handleClick }) {
  return (
    <>
      <ListItemButton
        onClick={handleClick}
        dense
        component="div"
        sx={styles.listItemRoot}
      >
        {open ? (
          <ExpandLess fontSize="small" sx={styles.spandIcon} />
        ) : (
          <ExpandMore fontSize="small" sx={styles.spandIcon} />
        )}
        <ListItemText
          primary={
            <span style={{ fontSize: "0.88em" }} className="body2">
              Plan
            </span>
          }
          secondary={
            <span style={styles.userPlan} className="body2">
              {currentPlan}
            </span>
          }
        />
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem dense component="div" sx={styles.listItemRoot}>
            <ListItemText
              sx={{
                "& .MuiTypography-root": {
                  width: "100%",
                },
              }}
              primary={
                <>
                  <span
                    style={{
                      display: "flex",
                      fontWeight: 500,
                      justifyContent: "flex-end",
                    }}
                    className="body2"
                  >
                    {"Historial de datos"}
                  </span>
                </>
              }
              secondary={
                <span
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    columnGap: "0.5em",
                    justifyContent: "flex-end",
                  }}
                >
                  <span
                    style={{
                      marginTop: "0.5em",
                      fontWeight: 500,
                      background: "#8080802e",
                      padding: "0.1em 0.5em",
                      borderRadius: "5px",
                    }}
                    className="body2"
                  >
                    {RECORD_AVAILABLE_BY_PLAN[currentPlan]}
                  </span>
                </span>
              }
            />
          </ListItem>
          <ListItem dense component="div" sx={styles.listItemRoot}>
            <ListItemText
              sx={{
                "& .MuiTypography-root": {
                  width: "100%",
                },
              }}
              primary={
                <span
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    fontWeight: 500,
                  }}
                  className="body2"
                >
                  {"Herramientas restringidas"}
                </span>
              }
              secondary={
                <span
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    columnGap: "0.5em",
                    justifyContent: "flex-end",
                  }}
                >
                  {TOOLS_DISABLED_BY_PLAN[currentPlan].map((tool) => (
                    <span
                      key={tool}
                      style={{
                        background: "#8080802e",
                        padding: "0.1em 0.5em",
                        borderRadius: "5px",
                        fontWeight: 500,
                      }}
                      className="body2"
                    >
                      {tool}
                    </span>
                  ))}
                </span>
              }
            />
          </ListItem>
        </List>
      </Collapse>
    </>
  );
}
