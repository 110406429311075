import { useState, useEffect } from "react";

import { Button } from "@mui/material";

import styles from "./styles";
import { uniqueId } from "utils/utils";

const WivoButton = ({
  sx,
  data_intercom_target,
  preventMultiClick,
  disabled,
  id = `wivo-button-${uniqueId()}`,
  handleOnClick = () => {},
  label,
  startIcon,
  color = "primary",
  outlined = undefined,
  text = undefined,
  size,
  className,
  data_cy,
  minWidth,
}) => {
  const [isDisabled, seDisabled] = useState(false);

  useEffect(() => {
    seDisabled(disabled);
  }, [disabled]);

  const handleClick = (event) => {
    if (preventMultiClick) {
      seDisabled(true);
      setTimeout(() => {
        seDisabled(false);
      }, 150);
    }
    handleOnClick(event);
  };

  return (
    <Button
      data-cy={data_cy}
      className={className}
      data-intercom-target={data_intercom_target}
      disabled={isDisabled}
      id={data_intercom_target ? data_intercom_target : id}
      sx={{
        ...(outlined
          ? styles[`outlined_${color}`]
          : text
          ? styles["text"]
          : styles[color]),
        ...sx,
        "& .MuiButton-startIcon": {
          margin: label ? "0 8px 0 -4px" : 0,
        },
        minWidth: minWidth ?? 0,
      }}
      variant={outlined ? "outlined" : text ? "text" : "contained"}
      disableElevation
      onClick={handleClick}
      startIcon={startIcon}
      size={size}
    >
      {label}
    </Button>
  );
};

export default WivoButton;
