import { useEffect } from "react";

import { hashHistory } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";

import Progress from "components/Progress/Progress";

const isDemo = process.env.REACT_APP_DEMO?.toLowerCase() === "true";

const Auth0Signup = (props) => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  const signup = () => {
    return loginWithRedirect({ screen_hint: "signup" });
  };

  useEffect(() => {
    async function checkUser() {
      if (isAuthenticated) {
        await hashHistory.push("/home");
      } else {
        signup();
      }
    }
    if (!isDemo) checkUser(); // called async checkUser()
  }, [isAuthenticated, loginWithRedirect]);

  if (isDemo) {
    hashHistory.push("/home");
    return null;
  }

  return <Progress msg="Cargando..." type="blocker" />;
};

export default Auth0Signup;
