import React from "react";
import { Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { useEffect } from "react";

const Toast = ({ show, close, data }) => {
  const { type, title, text } = { ...data };
  const getType = () => {
    switch (type) {
      case "success":
        return { color: "var(--green-base)", Icon: CheckCircleRoundedIcon };
      case "error":
        return { color: "var(--red-base)", Icon: CancelRoundedIcon };
      default:
        return { color: "var(--green-base)", Icon: CheckCircleRoundedIcon };
    }
  };
  const { color, Icon } = getType();

  useEffect(() => {
    if (show)
      setTimeout(() => {
        close && close();
      }, 10000);
  }, [show]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={show}
      onClose={close}
      key="snackbar toast"
    >
      <div
        style={{
          borderRadius: "5px",
          width: "378px",
          padding: "15px 55px",
          backgroundColor: "var(--gray-90)",
          color: "white",
          borderLeft: `5px solid ${color}`,
        }}
      >
        <Icon style={{ color, position: "absolute", top: 22, left: 22 }} />
        <CloseIcon
          onClick={close}
          style={{
            color: "white",
            position: "absolute",
            right: "14px",
            cursor: "pointer",
          }}
        />
        <span
          className="toast-title"
          style={{ fontSize: "1.142rem", margin: "5px 0", color: "#ffffff" }}
        >
          {title}
        </span>
        <p style={{ fontSize: "0.78rem", margin: "5px 0", color: "#ffffff" }}>
          {text}
        </p>
      </div>
    </Snackbar>
  );
};

export default Toast;
