import { dateRanges } from "components/NewCalendar/ranges";
import moment from "moment";
import {
  highCommissions,
  highShippingCost,
  profitability,
  unprofitable,
} from "./cubeQuery";
import { DATA_CY } from "utils/app/cypressConstants";

export const getFiltersByQuickFilter = (qf, filtersOptions) => {
  let filter = undefined;
  for (var key in filtersOptions) {
    const { quick_filter } = filtersOptions[key];
    const qfKeys = Object.keys(quick_filter);
    const existQF = qfKeys.indexOf(qf);

    if (existQF !== -1) {
      filter = quick_filter[qf].filters;
      break;
    }
  }
  return filter;
};

export const getSectionByQuickFilter = (qf, filtersOptions) => {
  let section = undefined;
  for (var key in filtersOptions) {
    const { quick_filter } = filtersOptions[key];
    const qfKeys = Object.keys(quick_filter);
    const existQF = qfKeys.indexOf(qf);

    if (existQF !== -1) {
      section = key;
      break;
    }
  }
  return section;
};

const { COMPONENTS } = DATA_CY;
export const QUICK_FILTERS_OPTIONS = (thresholds = {}) => {
  const highCommissionsValue = thresholds?.highCommissions || 18;
  const highShippingCostValue = thresholds?.highShippingCost || 5000;
  const unprofitableValue = thresholds?.unprofitable || 70;
  const totalProfitability = thresholds?.totalProfitability || 0;
  return {
    "Rentabilidad y costos": {
      plan: "starter",
      quick_filter: {
        "Poco rentable": {
          "data-cy": COMPONENTS.unprofitable,
          query: unprofitable,
          filters: [
            {
              member: "Ordersbyitem.avgSellerPayment",
              operator: "lt",
              values: [`${unprofitableValue}`],
            },
            {
              member: "Ordersbyitem.paymentStatus",
              operator: "notEquals",
              values: ["noinformation"],
            },
            {
              or: [
                {
                  member: "Ordersbyitem.itemStatus",
                  operator: "equals",
                  values: ["paid"],
                },
                {
                  member: "Ordersbyitem.sellerPaymentDimension",
                  operator: "notEquals",
                  values: ["0"],
                },
              ],
            },
          ],
        },
        "Costo de envío alto": {
          "data-cy": COMPONENTS.highShippingCost,
          query: highShippingCost,
          filters: [
            {
              member: "Ordersbyitem.shipmentCosts",
              operator: "gt",
              values: [`${highShippingCostValue}`],
            },
          ],
        },
        "Comisiones altas": {
          "data-cy": COMPONENTS.highCommissions,
          query: highCommissions,
          filters: [
            {
              member: "Ordersbyitem.rateFeesCost",
              operator: "gte",
              values: [`${highCommissionsValue}`],
            },
          ],
        },
      },
    },
    "Rentabilidad total": {
      plan: "starter",
      quick_filter: {
        "Baja rentabilidad": {
          "data-cy": COMPONENTS.lowProfitability,
          query: profitability,
          filters: [
            {
              member: "Ordersbyitem.avgRentTotal",
              operator: "gte",
              values: [`0`],
            },
            {
              member: "Ordersbyitem.avgRentTotal",
              operator: "lt",
              values: [`${totalProfitability}`],
            },
            {
              member: "Ordersbyitem.paymentStatus",
              operator: "notEquals",
              values: ["noinformation"],
            },
            {
              or: [
                {
                  member: "Ordersbyitem.itemStatus",
                  operator: "equals",
                  values: ["paid"],
                },
                {
                  member: "Ordersbyitem.sellerPaymentDimension",
                  operator: "notEquals",
                  values: ["0"],
                },
              ],
            },
          ],
        },
        "Rentabilidad Negativa": {
          "data-cy": COMPONENTS.negativeProfitability,
          query: profitability,
          filters: [
            {
              member: "Ordersbyitem.avgRentTotal",
              operator: "lt",
              values: [`0`],
            },
            {
              member: "Ordersbyitem.paymentStatus",
              operator: "notEquals",
              values: ["noinformation"],
            },
            {
              or: [
                {
                  member: "Ordersbyitem.itemStatus",
                  operator: "equals",
                  values: ["paid"],
                },
                {
                  member: "Ordersbyitem.sellerPaymentDimension",
                  operator: "notEquals",
                  values: ["0"],
                },
              ],
            },
          ],
        },
        "Buena rentabilidad": {
          "data-cy": COMPONENTS.godProfitability,
          query: profitability,
          filters: [
            {
              member: "Ordersbyitem.avgRentTotal",
              operator: "gte",
              values: [`${totalProfitability}`],
            },
            {
              member: "Ordersbyitem.paymentStatus",
              operator: "notEquals",
              values: ["noinformation"],
            },
            {
              or: [
                {
                  member: "Ordersbyitem.itemStatus",
                  operator: "equals",
                  values: ["paid"],
                },
                {
                  member: "Ordersbyitem.sellerPaymentDimension",
                  operator: "notEquals",
                  values: ["0"],
                },
              ],
            },
          ],
        },
      },
    },
    Pagos: {
      plan: "free",
      quick_filter: {
        "Recién pagadas": {
          "data-cy": COMPONENTS.justPaid,
          filters: [
            {
              member: "Ordersbyitem.paymentPaidDate",
              operator: "inDateRange",
              values: [
                dateRanges["Last 7 days"].start,
                dateRanges["Last 7 days"].end,
              ],
            },
            {
              member: "Ordersbyitem.paymentStatus",
              operator: "equals",
              values: ["paid"],
            },
          ],
        },
        "Sin pagos en +30d": {
          "data-cy": COMPONENTS.noPayments30d,
          filters: [
            {
              member: "Ordersbyitem.paymentPaidDate",
              operator: "inDateRange",
              values: [
                moment().startOf("years").format("YYYY-MM-DD"),
                dateRanges["Last 30 days"].start,
              ],
            },
            {
              member: "Ordersbyitem.paymentStatus",
              operator: "notEquals",
              values: ["paid"],
            },
          ],
        },
        "Con Pagos": {
          "data-cy": COMPONENTS.paymentStatusAvailable,
          filters: [
            {
              member: "Ordersbyitem.paymentStatus",
              operator: "notEquals",
              values: ["noinformation"],
            },
          ],
        },
        Pagado: {
          "data-cy": COMPONENTS.paidPaymentStatus,
          filters: [
            {
              member: "Ordersbyitem.paymentStatus",
              operator: "equals",
              values: ["paid"],
            },
          ],
        },
        "No Pagado": {
          "data-cy": COMPONENTS.unpaidPaymentStatus,
          filters: [
            {
              member: "Ordersbyitem.paymentStatus",
              operator: "equals",
              values: ["unpaid"],
            },
          ],
        },
        "Sin Información": {
          "data-cy": COMPONENTS.noinformationPaymentStatus,
          filters: [
            {
              member: "Ordersbyitem.paymentStatus",
              operator: "equals",
              values: ["noinformation"],
            },
          ],
        },
        Desconocido: {
          "data-cy": COMPONENTS.noinformationPaymentStatus,
          filters: [
            {
              member: "Ordersbyitem.paymentStatus",
              operator: "equals",
              values: ["unknownpaymentstatus"],
            },
          ],
        },
      },
    },
    "Estado de Orden": {
      plan: "free",
      quick_filter: {
        Regular: {
          "data-cy": COMPONENTS.regularItemStatus,
          filters: [
            {
              //Se applica los filtros con los nombres de los objetos para que al pasarlos por url al reporte se pase por el valor, no por el key
              member: "Ordersbyitem.itemStatus",
              operator: "equals",
              values: ["paid"],
            },
          ],
        },
        Devuelta: {
          "data-cy": COMPONENTS.returnedItemStatus,
          filters: [
            {
              //Se applica los filtros con los nombres de los objetos para que al pasarlos por url al reporte se pase por el valor, no por el key
              member: "Ordersbyitem.itemStatus",
              operator: "equals",
              values: ["returned"],
            },
          ],
        },
        Pendiente: {
          "data-cy": COMPONENTS.pendingItemStatus,
          filters: [
            {
              //Se applica los filtros con los nombres de los objetos para que al pasarlos por url al reporte se pase por el valor, no por el key
              member: "Ordersbyitem.itemStatus",
              operator: "equals",
              values: ["pending"],
            },
          ],
        },
        Cancelada: {
          "data-cy": COMPONENTS.canceledItemStatus,
          filters: [
            {
              //Se applica los filtros con los nombres de los objetos para que al pasarlos por url al reporte se pase por el valor, no por el key
              member: "Ordersbyitem.itemStatus",
              operator: "equals",
              values: ["canceled"],
            },
          ],
        },
      },
    },
    "Tipo de envío": {
      plan: "free",
      quick_filter: {
        Fulfillment: {
          "data-cy": COMPONENTS.fullfilmentLogisticStatus,
          filters: [
            {
              member: "Ordersbyitem.logisticType",
              operator: "equals",
              values: ["fulfilment"],
            },
          ],
        },
        "No Fulfillment": {
          "data-cy": COMPONENTS.noFullfilmentLogisticStatus,
          filters: [
            {
              member: "Ordersbyitem.logisticType",
              operator: "equals",
              values: ["standard"],
            },
          ],
        },
        Flex: {
          "data-cy": COMPONENTS.flex,
          filters: [
            {
              member: "Ordersbyitem.logisticType",
              operator: "equals",
              values: ["flex"],
            },
          ],
        },
        Desconocido: {
          "data-cy": COMPONENTS.unknowLogisticStatus,
          filters: [
            {
              member: "Ordersbyitem.logisticType",
              operator: "equals",
              values: ["unknownlogistictype"],
            },
          ],
        },
        "Sin Información": {
          "data-cy": COMPONENTS.noInformationLogisticStatus,
          filters: [
            {
              member: "Ordersbyitem.logisticType",
              operator: "equals",
              values: ["noinformation"],
            },
          ],
        },
      },
    },
    Marketplaces: {
      plan: "free",
      quick_filter: {
        subQuery: {
          "data-cy": COMPONENTS.marketplaces,
          filters: [
            {
              member: "SourceType.name",
            },
          ],
          mainMeasure: "Ordersbyitem.orders",
          mainDimension: "SourceType.name",
        },
      },
    },
  };
};
