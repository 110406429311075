import React from "react";

import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

const AddReport = (props) => {
  const { icon, title, subtitle, onActionClick, id, data_cy } = props;
  return (
    <Grid
      data-cy={data_cy}
      id={id}
      data-intercom-target={`create-report-${id}`}
      container
      direction="row"
      onClick={onActionClick}
      sx={{
        cursor: "pointer",
        ".MuiGrid-item:hover": { background: "#F1F3F4" },
        p: 2,
      }}
    >
      <Grid item xs={2} sm={1} md={2}>
        {icon}
      </Grid>
      <Grid item xs={10} sm={11} md={10}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography
            variant="body1"
            component="span"
            sx={{ color: "var(--blue-gray-5)" }}
          >
            {title}
          </Typography>
          <small
            style={{
              fontSize: "0.87rem",
              marginTop: "0.5rem",
              color: "var(--blue-gray-4)",
            }}
          >
            {subtitle}
          </small>
        </div>
      </Grid>
    </Grid>
  );
};

export default AddReport;
