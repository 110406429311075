const styles = {
  inviteUserRoot: { display: "flex", alignItems: "center" },
  inviteButton: {
    color: "#78909C",
    fontSize: "0.78rem",
    display: { xs: "none", sm: "none", md: "flex" },
  },
  inviteIcon: { color: "#78909C", display: { sm: "flex", md: "none" } },
};

export default styles;
