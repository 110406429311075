import { localization } from "localization/es";

const { APP, BUTTONS } = localization;

export const TOAST_TYPES = {
  SUCCESS: "success",
  ERROR: "error",
};

export const PRODUCT_COST = {
  UNKNOWN: "unknown",
  KNOWN: "known",
};
export const ERROR_TITLE = localization.APP.ACTION_ERROR;
export const TOAST_INITIAL_STATE = {
  show: false,
  type: "success",
  title: "",
  text: "",
};

export const MARKETPLACE_NAME_EXT_REFERENCE = {
  MCL: "Mercado Libre",
  LCL: "Linio",
  RCL: "Ripley",
  FCL: "Falabella",
  NFCL: "Falabella Seller Center",
  PCL: "Paris",
  //magento: "/images/magento-logo.webp",
  DCL: "Dafiti",
  SCL: "Sodimac",
  VTX: "Vtex",
  PTS: "Prestashop",
  SPF: "Shopify",
  WCM: "Woocommerce",
  LVP: "Liverpool",
  WLM: "Walmart",
  AMZ: "Amazon",
};

export const SOURCE_KEY_BY_EXT_REFERENCE = {
  MCL: "mercadolibre",
  NFCL: "newfalabella",
};

export const MARKETPLACE_EXT_REFERENCE = {
  "mercado libre": "MCL",
  linio: "LCL",
  ripley: "RCL",
  falabella: "FCL",
  "falabella seller center": "NFCL",
  paris: "PCL",
  dafiti: "DCL",
  sodimac: "SCL",
  vtex: "VTX",
  prestashop: "PTS",
  shopify: "SPF",
  woocommerce: "WCM",
  walmart: "WLM",
  amazon: "AMZ",
};

export const COLORS = {
  FUCHSIA_BASE: "#ED266E",
  GREEN_BASE: "#2FBD55",
  NEON_BLUE_BASE: "#5346F9",
  YELLOW_BASE: "#FAC802",
  ORANGE_BASE: "#FF6131",
  BLUE_BASE: "#3770EF",
  BLUE_SMOOTH: "#78909C", //"#E2EBFD",
  RED_BASE: "#F22C36",
  "gray-90": "#3e4345",
  "gray-20": "#f1f3f4",
  "gray-30": "#e3e5e5",
  GRAY_40: "#C6CACC",
  "gray-60": "#919699",
  "gray-80": "#5e6366",
  COLOR_1: "#5ad8a6",
  COLOR_2: "#5e7092",
  COLOR_3: "#f6bd18",
  COLOR_4: "#6f5efa",
  COLOR_5: "#6ec8ec",
  COLOR_6: "#945fb9",
  COLOR_7: "#ff9845",
  COLOR_8: "#299796",
  COLOR_9: "#fe99c3",
};

export const LOGOUT_REDIRECT = "https://www.wivoanalytics.com/";
export const DEMO_URL = "https://demo.wivoanalytics.com/#/signup";
export const HAS_TEAM = "HAS_TEAM";
export const DEMO = "DEMO";
export const ONBOARDING_STAGES = {
  NAME: "NAME",
  TENANT_NAME: "tenant_name",
  CONNECT: "connect",
  COUNTRY: "country",
  INTERESTS: "interests",
  FLOW: "flow",
  OBJECTIVES: "objectives",
  MARKETPLACES: "marketplaces",
  INTEGRATION: "integration?onBoarding=true",
  VALIDATE_ACCOUNT: "validate_account",
};
export const SEGMENT_EVENT = {
  EXPORT_REPORT: "export",
  INVITE_USER: "Invite Sent",
  ONBOARDING_REGISTER_MARKETPLACE: "onboarding-marketplace-register",
  REGISTER_MARKETPLACE: "marketplace-register",
  LOAD_REPORT: "load-report",
  CREATE_REPORT: "create-report",
  REGISTER_NAME_AUTH0: "onboarding-auth0-register",
  DIPLICATE_REPORT: "Duplicate-report",
  SAVE_REPORT: "Save-report",
  GAIDED_TOUR: "guided_tour",
  FOLLOW_PUBLICATION: "follow_publication",
  UNFOLLOW_PUBLICATION: "unfollow_publication",
  GO_TO_PUBLICATION: "go_to_publication",
  FOLLOW_KEYWORD: "follow_keyword",
  UNFOLLOW_KEYWORD: "unfollow_keyword",
  GO_TO_KEYWORD: "go_to_keyword",
  DELETE_SOURCE: "source_delete",
  TRIAL_ACTIVATED: "trial_activated",
  TRIAL_AUTO_ACTIVATED: "trial_auto_activated",
  REQUEST_LOAD_COSTS: "request_to_load_costs",
};

export const CHECK_UPDATE = 1000 * 60 * 10;
export const CHECK_UPDATE_DAILY = 1000 * 60 * 60 * 24;

export const ENV_VARIABLES = [
  {
    key: "REACT_APP_AUTH0_DOMAIN",
    value: process.env.REACT_APP_AUTH0_DOMAIN,
  },
  {
    key: "REACT_APP_AUTH0_CLIENT_ID",
    value: process.env.REACT_APP_AUTH0_CLIENT_ID,
  },
  {
    key: "REACT_APP_AUTH0_AUDIENCE",
    value: process.env.REACT_APP_AUTH0_AUDIENCE,
  },
  {
    key: "REACT_APP_CUBEJS_URL",
    value: process.env.REACT_APP_CUBEJS_URL,
  },
  { key: "REACT_APP_CLAIMS", value: process.env.REACT_APP_CLAIMS },
  {
    key: "REACT_APP_HIDE_DASHBOARD_BY_ID",
    value: process.env.REACT_APP_HIDE_DASHBOARD_BY_ID,
  },
  {
    key: "REACT_APP_DEV_MODE",
    value: process.env.REACT_APP_DEV_MODE,
  },
  {
    key: "REACT_APP_REPORT_SETTINGS_URL",
    value: process.env.REACT_APP_REPORT_SETTINGS_URL,
  },
  {
    key: "REACT_APP_ALPACA_URL",
    value: process.env.REACT_APP_ALPACA_URL,
  },
  { key: "REACT_APP_DEMO", value: process.env.REACT_APP_DEMO },
];

export const WELCOME_MESSAGE = [
  {
    key: "welcome",
    message: APP.WELCOME_MESSAGE,
    action: BUTTONS.NEXT,
  },
  {
    key: "go_marketplaces",
    message: APP.WELCOME_AMRKETPLACE_PROMO,
    action: BUTTONS.GO_MARKTPLACE,
  },
];

export const TENANT_STATUS = {
  ONBOARDING: "ONBOARDING",
  LOADING: "LOADING",
  ENABLED: "ENABLED",
};

export const PLANS_HIERARCHY = {
  pro: { hierarchy: ["pro", "starter", "free"], historicalData: "2-years" },
  starter: { hierarchy: ["starter", "free"], historicalData: "3-months" },
  free: { hierarchy: ["free"], historicalData: "1-months" },
};

export const SALES_TOUR_DATE = "2023-02-24";
export const ORDERS_TOUR_DATE = "2023-04-19";

export const KEYWORDS_PLAN = {
  free: 4,
  starter: 12,
  pro: 40,
};

export const PUBLISHING_PLAN = {
  free: 20,
  starter: 60,
  pro: 200,
};

export const ADDONS = {
  publications: 20,
  keywords: 5,
};

export const PLAN_MANAGEMENT = {
  ACTIVATE_TRIAL: "ACTIVATE_TRIAL",
  UPGRADE_PLAN: "UPGRADE_PLAN",
};

export const PREMIUM_BASE_ROUTES = {
  reports: { path: "/reports", plan: "starter" },
  profitability: { path: "/profitability", plan: "pro" },
};

export const TRIALS = {
  PRO: {
    title: "¿Quieres desbloquear todas las funcionalidades de Wivo?",
    subtitle: `Puedes probar el plan PRO <strong>gratis</strong>, sin cobros adicionales ni tarjetas de crédito.`,
    features: [
      "Editor de Reportes",
      "Historial de datos desde Año anterior",
      "Costos y atributos de productos",
      "Dashboard de Rentabilidad",
    ],
    cta: "ACTIVAR PERIODO DE PRUEBA DE 7 DÍAS",
  },
};

export const UPGRADE = {
  PRO: {
    title: "¿Quieres desbloquear todas las funcionalidades de Wivo?",
    subtitle: "Cámbiate al plan PRO y tendrás acceso a:",
    features: [
      "Editor de Reportes",
      "Historial de datos desde Año anterior",
      "Costos y atributos de productos",
      "Dashboard de Rentabilidad",
    ],
    cta: "QUIERO QUE ME CONTACTEN",
  },
};

export const TOOLS_DISABLED_BY_PLAN = {
  free: ["Reportes", "Rentabilidad"],
  starter: ["Rentabilidad"],
  pro: ["Ninguna"],
};

export const RECORD_AVAILABLE_BY_PLAN = {
  free: "Desde mes anterior",
  starter: "Desde hace 3 meses",
  pro: "Desde el año pasado",
};

export const LIMIT_AFTER_FINISH_TRIAL = 30;
export const TRIAL_RESTART_PERIOD = 90;

export const RECOMMENDED_MARKETS_BY_COUNTRY = {
  CL: ["mercadolibre", "newfalabella"],
  PE: ["mercadolibre", "newfalabella"],
  CO: ["mercadolibre", "newfalabella"],
  MEX: ["mercadolibre"],
  AR: ["mercadolibre"],
  UY: ["mercadolibre"],
};

export const RECOMMENDED_NAMES_MARKETS_BY_COUNTRY = {
  CL: ["Mercado libre", "Falabella"],
  PE: ["mercadolibre", "Falabella"],
  CO: ["Mercado Libre", "Falabella"],
  MEX: ["Mercado Libre"],
  AR: ["Mercado Libre"],
  UY: ["Mercado Libre"],
};

export const URL_MARKETS_BY_COUNTRY = {
  linio: {
    CL: "https://sellercenter.linio.cl",
    PE: "https://sellercenter.linio.com.pe",
    CO: "https://sellercenter.linio.com.co",
    MEX: "https://sellercenter.linio.com.mx",
  },
  dafiti: {
    CL: "https://sellercenter.dafiti.cl",
    CO: "https://sellercenter.dafiti.com.co",
  },
};

export const MARKETPLACES_BY_EXTERNAL_REFERENCE = {
  "Falabella - Seller Link": "FCL",
  Vtex: "VTX",
  Shopify: "SPF",
  "Falabella - Seller Center": "NFCL",
  Sodimac: "SCL",
  Liverpool: "LVP",
  "Falabella – Seller Link": "FCL",
  Ripley: "RCL",
  Walmart: "WLM",
  Linio: "LCL",
  "Mercado Libre": "MCL",
  Prestashop: "PTS",
  "Mercado Shops": "MSHOPS",
  Amazon: "AMZ",
  Dafiti: "DCL",
  Woocommerce: "WCM",
  "Amazon (Beta)": "AMZ",
  Paris: "PCL",
};

export const TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const REPORTS_IDs_BY_CARDS = {
  CHANNEL_DISTRIBUTION: {
    SourceType: {
      STAGING: 40,
      PRODUCTION: 35,
    },
    Source: {
      STAGING: 41,
      PRODUCTION: 36,
    },
  },
  CATEGORIES: {
    Category: {
      STAGING: 42,
      PRODUCTION: 37,
    },
    Brand: {
      STAGING: 43,
      PRODUCTION: 38,
    },
    InternalCategory: { STAGING: 62, PRODUCTION: 57 },
    InternalBrand: { STAGING: 63, PRODUCTION: 58 },
  },
  PRODUCTS: {
    STAGING: 44,
    PRODUCTION: 39,
  },
  INTERNAL_PRODUCTS: { STAGING: 64, PRODUCTION: 59 },
};

export const PROFIT_REPORTS_IDs_BY_CARDS = {
  "SourceType.name": {
    MARKETPLACE: {
      STAGING: 46,
      PRODUCTION: 49,
    },
    TOTAL: {
      STAGING: 47,
      PRODUCTION: 50,
    },
  },
  "ItemStatus.name": {
    MARKETPLACE: {
      STAGING: 48,
      PRODUCTION: 43,
    },
    TOTAL: {
      STAGING: 49,
      PRODUCTION: 44,
    },
  },
  "LogisticType.name": {
    MARKETPLACE: {
      STAGING: 50,
      PRODUCTION: 55,
    },
    TOTAL: {
      STAGING: 51,
      PRODUCTION: 56,
    },
  },
  "Ordersbyitem.internalBrand": {
    TOTAL: {
      STAGING: 52,
      PRODUCTION: 48,
    },
  },
  "Brand.name": {
    MARKETPLACE: {
      STAGING: 53,
      PRODUCTION: 47,
    },
  },
  "Category.name": {
    MARKETPLACE: {
      STAGING: 54,
      PRODUCTION: 41,
    },
  },
  "Ordersbyitem.internalCategory": {
    TOTAL: {
      STAGING: 55,
      PRODUCTION: 42,
    },
  },
  PRODUCTS: {
    MARKETPLACE: {
      STAGING: 56,
      PRODUCTION: 51,
    },
    TOTAL: {
      STAGING: 57,
      PRODUCTION: 52,
    },
  },
  COSTS: {
    MARKETPLACE: {
      STAGING: 58,
      PRODUCTION: 45,
    },
    TOTAL: {
      STAGING: 59,
      PRODUCTION: 46,
    },
  },
  PROFIT: {
    MARKETPLACE: {
      STAGING: 60,
      PRODUCTION: 53,
    },
    TOTAL: {
      STAGING: 61,
      PRODUCTION: 54,
    },
  },
};
