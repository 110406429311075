import React, { useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { registerEvent, registerIdentify, uniqueId } from "utils/utils";
import { inviteUser, registerUserDataAut0 } from "services/alpaca/request";
import { updateUserIdentity } from "redux/UserIdentity/actions";
import { SEGMENT_EVENT } from "utils/app/appConstants";
import { referUsers } from "services/almiqui/referred/request";
import { enqueueSnackbar } from "notistack";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const re = new RegExp(
  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*"
);

export default function InviteUser(props) {
  const {
    open,
    handleClose,
    tenant,
    userName,
    invitationSuccess,
    invitationsFail,
    referred = null,
    title,
    subtitle,
    addAction,
    description,
    options,
    limited = false,
  } = props;
  const identity = useSelector((state) => state.get("userIdentity"));
  const {
    userData: { user_id, user_metadata, email },
    accountData,
  } = identity;
  const milestones = user_metadata?.milestones ?? [];
  const findMilestone = (milestone) => {
    return milestones.find((mil) => mil === milestone);
  };
  const dispatch = useDispatch();
  const [invitations, setInvitations] = useState([
    { key: uniqueId(), value: undefined, error: false, errorMessage: "" },
    { key: uniqueId(), value: undefined, error: false, errorMessage: "" },
    { key: uniqueId(), value: undefined, error: false, errorMessage: "" },
  ]);

  const handleOnchange = (e, position) => {
    let error = undefined;
    const { value } = e.target;
    const newInvitatios = [...invitations];
    if (value !== "") {
      const exist = newInvitatios.find(
        (invitation) => invitation.value === value
      );

      if (!re.test(value)) {
        error = "Email inválido";
      }

      if (exist) {
        error = "Email duplicado";
      }
      newInvitatios[position].value = value;
      if (error) {
        newInvitatios[position].error = true;
        newInvitatios[position].errorMessage = error;
      } else {
        newInvitatios[position].error = false;
        newInvitatios[position].errorMessage = "";
      }
    } else {
      newInvitatios[position].value = undefined;
      newInvitatios[position].error = false;
      newInvitatios[position].errorMessage = "";
    }
    setInvitations(newInvitatios);
  };

  const addEmailInvitation = () => {
    setInvitations([
      ...invitations,
      { key: uniqueId(), value: undefined, error: false, errorMessage: "" },
    ]);
  };
  /**
   * Referir usuarios desde almiqiu
   */
  const handleError = () => {
    const toastData = {
      type: "error",
      title: "Error",
      text: "La invitación ha fallado. Asegúrese de que el usuario no haya sido invitado ya, o comuníquese con el equipo de soporte.",
    };
    enqueueSnackbar("", {
      variant: "snackbar",
      ...toastData,
    });
  };

  const handleReferUsers = () => {
    handleClose();
    const invitationsArray = invitations
      .filter((invitation) => invitation.value)
      .map((user) => user.value);
    const users = {
      tenant,
      userId: user_id,
      invitedBy: userName,
      invitedByEmail: email,
      users: invitationsArray,
      tenant_name: accountData?.client,
    };
    referUsers(users)
      .then((res) => {
        const { status, err, data } = res;
        if (err) throw new Error();
        if (status === 200) {
          const { response_status_codes } = data;
          let indx = 0;
          for (const invitationStatus of response_status_codes) {
            const type = invitationStatus === 201 ? "success" : "error";
            const title =
              invitationStatus === 201 ? "Invitación enviada" : "Error";
            const text =
              invitationStatus === 201
                ? `La invitacion a ${invitationsArray[indx]} ha sido enviada satisfactoriamente.`
                : invitationStatus === 409
                ? `El usuario del correo ${invitationsArray[indx]} ya existe en Wivo.`
                : `Ha fallado la invitación para el correo ${invitationsArray[indx]}.`;
            const toastData = { type, title, text };
            enqueueSnackbar("", {
              variant: "snackbar",
              ...toastData,
            });
            indx++;
          }
        }
      })
      .catch((err) => {
        handleError();
      });
  };
  /***
   * invitar usuarios desde alpaca
   */
  const handleInvitation = () => {
    handleClose();
    let sendInvitation = true;
    invitations.forEach((invitation, i) => {
      if (invitation.value && !invitations.error) {
        const body = {
          email: invitation.value,
          tenant: tenant,
          invited_by: userName,
          user_id,
        };
        inviteUser(body)
          .then((resp) => {
            const { status } = resp;
            if (status >= 200 && status <= 300) {
              if ( process.env.REACT_APP_DEV_MODE === "false") {
                if (!findMilestone("invited_user")) {
                  const user_metadata = {
                    milestones: [...milestones, "invited_user"],
                  };
                  registerUserDataAut0({
                    user_id,
                    user_data: { user_metadata },
                  }).then((resp) => {
                    registerIdentify(user_id, {
                      invited_user: true,
                    });
                    dispatch(
                      updateUserIdentity({
                        userData: {
                          ...identity.userData,
                          user_metadata: {
                            ...identity.userData.user_metadata,
                            milestones: [...milestones, "invited_user"],
                          },
                        },
                      })
                    );
                  });
                }
                registerEvent(
                  SEGMENT_EVENT.INVITE_USER,
                  {
                    invite_email: invitation.value,
                  },
                  {
                    context: {
                      groupId: tenant,
                    },
                  }
                );
              }
              if (sendInvitation) {
                invitationSuccess();
              } else sendInvitation = false;
            } else invitationsFail(); //resp.data.message== 'Error Creating user'
          })
          .catch((error) => {
            invitationsFail();
          });
      }
    });
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          style={{ marginTop: "1rem", color: "var(--blue-gray-5)" }}
        >
          {title ?? `Invita a tu equipo`}
        </DialogTitle>
        {subtitle && (
          <span
            className="body2"
            style={{ padding: "0 1.5em", fontSize: "0.8em" }}
          >
            {subtitle}
          </span>
        )}
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ color: "var(--blue-gray-5)", fontSize: "0.87em" }}
          >
            {description ??
              `Las personas que invites recibirán un correo con el acceso a la
            aplicación.`}
          </DialogContentText>
          {invitations.map((invitation, i) => (
            <TextField
              size="small"
              inputProps={{ style: { color: "var(--blue-gray-5)" } }}
              autoComplete="new-email"
              id={invitation.key}
              style={{ marginTop: "1rem" }}
              key={invitation.key}
              fullWidth
              label="Email"
              value={invitation.value}
              onChange={(e) => handleOnchange(e, i)}
              type="email"
              error={invitation.error}
              helperText={invitation.errorMessage}
            />
          ))}
          <div
            style={{
              marginTop: "2rem",
              color: "#3770EF",

              marginBottom: "1rem",
            }}
          >
            <small style={{ cursor: "pointer" }} onClick={addEmailInvitation}>
              {!limited && "+ Agregar otra invitación"}
            </small>
          </div>
          {options && (
            <ul style={{ padding: "0 1em" }}>
              {options.map((option) => (
                <li className="body2" style={{ display: "list-item" }}>
                  {option}
                </li>
              ))}
            </ul>
          )}
        </DialogContent>
        <DialogActions style={{ padding: "1rem" }}>
          <Button
            sx={{
              color: "#78909C",
              borderColor: "#78909C",
              fontSize: "0.78rem",
            }}
            disableElevation
            onClick={handleClose}
            variant="outlined"
          >
            {"Volver"}
          </Button>
          <Button
            sx={{
              background: "#f22c36",
              fontSize: "0.78rem",
              ":hover": {
                background: "#f22c36",
                opacity: "0.7",
                color: "#fff",
              },
            }}
            variant="contained"
            disableElevation
            onClick={() => {
              if (referred) return handleReferUsers();
              else return handleInvitation();
            }}
            disabled={
              invitations.find((invitation) => invitation.error) ||
              !invitations.find((invitation) => invitation.value)
                ? true
                : false
            }
          >
            {"Invitar"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
