import { useState } from "react";

import { Divider, Menu, Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";

import { InviteUsers } from "../InviteUsers/InviteUsers";
import { Profile } from "./Profile";
import { ProfileMenu } from "./ProfileMenu";
import { TENANT_STATUS } from "utils/app/appConstants";
import styles from "./styles";
import { Trials } from "./Trials";
import { IntegrationSection } from "./IntegrationSection";

export function ProfileSection() {
  const userIdentity = useSelector((state) => state.get("userIdentity"));
  const [anchorElUser, setAnchorElUser] = useState(null);
  const onBoardingState = useSelector((state) => state.get("onBoarding"));
  const onBoardingFinish = onBoardingState?.finish;

  const {
    accountData,
    isDemo: { demoEnabled },
  } = userIdentity;
  const tenant_status = accountData?.properties?.tenant_status;
  const currentPlan = accountData?.properties?.plan;

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {currentPlan?.toLowerCase() !== "pro" && <Trials />}
      {!tenant_status && !demoEnabled && onBoardingFinish && (
        <Skeleton
          variant="text"
          width={80}
          height={20}
          sx={{ margin: "0px 0px 0px 2.3em" }}
        />
      )}
      {
        //tenant_status === TENANT_STATUS.ENABLED &&
        !demoEnabled && onBoardingFinish && <InviteUsers />
      }
      {!tenant_status && !demoEnabled && onBoardingFinish && (
        <Box sx={{ p: "0 0.5em" }} onClick={() => {}}>
          <Skeleton variant="text" width={80} height={20} />
        </Box>
      )}
      {
        //tenant_status === TENANT_STATUS.ENABLED &&
        !demoEnabled && onBoardingFinish && (
          <Box sx={{ p: "0 0.5em" }} onClick={() => {}}>
            <IntegrationSection />
          </Box>
        )
      }
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={styles.divider}
      />
      {!tenant_status && onBoardingFinish && (
        <Box sx={{ p: "0 0.5em" }} onClick={() => {}}>
          <Skeleton variant="text" width={80} height={20} />
        </Box>
      )}
      <Box
        onClick={handleOpenUserMenu}
        aria-label="account of current user"
        aria-controls="menu-profile"
        aria-haspopup="true"
      >
        <Profile />
      </Box>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-profile"
        anchorEl={anchorElUser}
        PaperProps={styles.profileMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <ProfileMenu handleClose={handleCloseUserMenu} />
      </Menu>
    </Box>
  );
}
