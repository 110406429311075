export default (isReportList, isBannerVisible, path) => ({
  container: {
    margin: "0px 1.3vh 0px 0px",
    marginLeft: 0,
    position: "fixed",
    height: isBannerVisible
      ? "calc(100vh - 64px - 48px)"
      : "calc(100vh - 64px)",
    width: "100%",
    background:
      path && path.includes("/onBoarding/stage")
        ? "#FFF"
        : "var(--blue-gray-1)",
    boxSizing: "border-box",
    overflow: "auto",
    maxHeight: isReportList ? "unset" : "100%",
  },
});
