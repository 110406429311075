const styles = {
  listRoot: {
    width: "100%",
    minWidth: 280,
    maxHeight: 550,
    bgcolor: "background.paper",
    overflow: "auto",
  },
  listItemRoot: {
    "& .MuiListItemText-root": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
  },
  spandIcon: {
    "&.MuiSvgIcon-root": {
      color: "#78909C",
    },
  },
  userPlan: {
    fontSize: "1rem",
    textTransform: "uppercase",
    fontWeight: "500",
  },
  accountStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    columnGap: "0.5em",
  },
  newAccount: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    columnGap: "0.5em",
  },
  newAccountIcon: {
    "&.MuiSvgIcon-root": {
      fontSize: "1em",
      color: "#5b717b",
    },
  },
  logOutroot: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    columnGap: "0.5em",
  },
  divider: {
    background: "#E3E5E5",
    mr: "1em",
    ml: "1em",
    display: { xs: "none", sm: "none", md: "flex" },
  },
  profileRoot: { display: "flex", alignItems: "center", cursor: "pointer" },
  profileArrowIcon: { color: "#78909C", display: { xs: "none", md: "flex" } },
  profileMenuIcon: { color: "#78909C", display: { xs: "flex", md: "none" } },
  profileMenu: {
    elevation: 0,
    sx: {
      width: { xs: "75%", sm: "50%", md: "30%", lg: "auto" },
      overflow: "visible",
      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
      mt: 1.5,
      "& .MuiAvatar-root": {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
      },
      "&:before": {
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: "background.paper",
        transform: "translateY(-50%) rotate(45deg)",
        zIndex: 0,
      },
    },
  },
  icons: {
    fontSize: "large",
    color: "var(--blue-gray-4)",
  },
  account: {
    //mr: "1em",
    display: { xs: "none", sm: "none", md: "flex" },
    cursor: "pointer",
    color: "#78909C",
    textTransform: "capitalize",
    textAlign: "end",
  },
  trialRoot: {
    display: "flex",
    alignItems: "center",
    mr: { xs: "0", sm: "1em" },
    background: "#f22c362b",
    borderRadius: "5px",
    padding: "0.5rem",
    justifyContent: "center",
  },
  clientRoot: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  planName: {
    fontSize: "0.8rem",
    display: "flex",
    justifyContent: "flex-end",
  },
  planSecondary: {
    margin: 0,
    textTransform: "capitalize",
    display: "flex",
    justifyContent: "flex-end",
  },
  plan: {
    background: "rgb(47 189 85 / 14%)",
    borderRadius: "16px",
    color: "#2FBD55",
    fontWeight: 600,
    border: "solid 1px #2FBD55",
    textTransform: "uppercase",
    letterSpacing: "0.04rem",
    fontSize: "0.725rem",
    height: "16px",
    display: "inline-flex",
    alignItems: "center",
    paddingLeft: "4.8px",
    paddingRight: "4.8px",
  },
};

export default styles;
