import { basicHeader, makeRequest } from "services/helper";
import * as REQUEST from "./endpoints";

export function sendEmail(emailData) {
  const options = {
    method: "POST",
    headers: basicHeader,
    body: JSON.stringify(emailData),
  };
  return makeRequest(REQUEST.sendEmailEndPoint(), options);
}
