import * as CONST from "./constant";

export function loadIntegrationValidationData(data) {
  return {
    type: CONST.LOAD_INTEGRATION_VALIDATION_DATA,
    payload: data,
  };
}

export function updateIntegrationValidationData(newData) {
  return {
    type: CONST.UPDATE_INTEGRATION_VALIDATION_DATA,
    payload: newData,
  };
}

export function removeIntegrationValidationData(identifier) {
  return {
    type: CONST.REMOVE_INTEGRATION_VALIDATION_DATA,
    payload: identifier,
  };
}
