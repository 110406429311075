import { useState, useEffect, Fragment } from "react";

import { Alert } from "@mui/material";
import WivoButton from "components/WivoButton/Button";
import RefreshIcon from "@mui/icons-material/Refresh";

import { localization } from "localization/es";

const { APP } = localization;

export function ReloadAlert({ open, initialMinute = 0, initialSeconds = 5 }) {
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    window.location.reload();
  };

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
          window.location.reload();
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const action = (
    <Fragment>
      <WivoButton
        label={APP.PAGE_RELOAD}
        handleOnClick={handleClose}
        startIcon={<RefreshIcon fontSize="small" />}
      ></WivoButton>
    </Fragment>
  );

  return (
    <div>
      <Alert
        action={action}
        severity="warning"
        sx={{ width: "100%", pl: 5, pr: 7 }}
      >
        {`${APP.AUTOMATICALLY_RELOAD_PAGE}${
          minutes === 0 && seconds === 0
            ? "ahora"
            : minutes + " : " + seconds < 10
            ? `0${seconds}`
            : "en " + seconds
        }                      
        `}
      </Alert>
    </div>
  );
}
