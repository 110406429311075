import * as CONST from "./constant";

export const initialState = {
  finish: true,
  pending: [],
  custom_shoice: undefined,
  keyAction: undefined,
  connectAction: false,
  source_selected: undefined,
};

function reducer(data = initialState, { type, payload }) {
  switch (type) {
    case CONST.LOAD_ONBOARDING_DATA:
      return payload;
    case CONST.UPDATE_ONBOARDING_DATA:
      return { ...data, ...payload };
    default:
      return data;
  }
}

export default reducer;
