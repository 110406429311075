import { printKodkodLog } from "utils/utils";

export const buildUrl = (apiUrl, method, params) => {
  let url = [apiUrl, method].join("/");
  if (typeof params !== undefined && params !== null && params !== undefined) {
    url = url.concat(params);
  }
  return url;
};

export const basicHeader = {
  "Access-Control-Allow-Headers":
    "Content-Type, Accept, Authorization, Origin, User-Agent, sentry-trace, baggage",
  "Access-Control-Allow-Methods": "GET, POST, PUT, OPTIONS, PATCH",
  "Access-Control-Allow-Origin": "*",
  //"Access-Control-Allow-Credentials": true,
  "Content-Type": "application/json",
};

export async function parseJSON(response) {
  try {
    const result = await response.json();
    return { data: result, status: response.status };
  } catch (err) {
    return {};
  }
}

export function checkStatus(response) {
  if (
    (response.status >= 200 && response.status < 300) ||
    (response.status >= 400 && response.status < 500)
  ) {
    return response;
  } else {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

export function makeRequest(url, options) {
  if ( process.env.REACT_APP_DEV_MODE === "true")
    printKodkodLog(`Request to --> ${url}`);
  return fetch(url, options)
    .then(checkStatus)
    .then(parseJSON)
    .then(({ data, status }) => ({ data, status }))
    .catch((err) => ({ err }));
}
