import { getTimeDimension } from "utils/utils";

export const publicationsQuery = (config) => {
  const query = {
    order: {
      "Ordersbyitem.sales": "desc",
    },
    total: true,
    measures: [
      "Ordersbyitem.units",
      "Ordersbyitem.sales",
      "Ordersbyitem.rateExpenses", // -> % costo marketplace
      "Ordersbyitem.expenses", // -> costo total
    ],
    timeDimensions: [{ ...getTimeDimension(config)[0] }],
    dimensions: [
      "Publication.image",
      "Publication.name",
      "Ordersbyitem.publishingIdentifier",
      "Ordersbyitem.sourceType",
      "Source.name",
    ],
  };
  return query;
};

export const PUBLICATIONS_HIDDEN_COLUMNS = {
  "Source.name": false,
  "Ordersbyitem.publishingIdentifier": false,
  "Ordersbyitem.sourceType": false,
  "Ordersbyitem.prodCostStatus": false,
  "Ordersbyitem.product": false,
  "Product.codproduct": false,
  "Ordersbyitem.itemStatus": false,
  "Ordersbyitem.logisticType": false,
  "Ordersbyitem.shipmentCosts": false,
  "Ordersbyitem.sellerPayment": false,
  "Ordersbyitem.prodCost": false,
  "Ordersbyitem.renTotal": false,
  "Ordersbyitem.costTotal": false,
  "Ordersbyitem.expenses": false,
  "Ordersbyitem.feesCost": false,
  "Ordersbyitem.adsCost": false,
};

export const unprofitable = {
  measures: ["Ordersbyitem.avgSellerPayment"],
  dimensions: [
    "Ordersbyitem.publishingIdentifier" /* "Ordersbyitem.product", "Ordersbyitem.prodCostStatus" */,
  ],
  timeDimensions: [
    {
      dimension: "Ordersbyitem.purchaseDate",
    },
  ],
};

export const highShippingCost = {
  measures: ["Ordersbyitem.shipmentCosts"],
  dimensions: [
    "Ordersbyitem.publishingIdentifier" /* "Ordersbyitem.product", "Ordersbyitem.prodCostStatus" */,
  ],
  timeDimensions: [
    {
      dimension: "Ordersbyitem.purchaseDate",
    },
  ],
};

export const highCommissions = {
  measures: ["Ordersbyitem.rateFeesCost"],
  dimensions: [
    "Ordersbyitem.publishingIdentifier" /* "Ordersbyitem.product", "Ordersbyitem.prodCostStatus" */,
  ],
  timeDimensions: [
    {
      dimension: "Ordersbyitem.purchaseDate",
    },
  ],
};

export const justPaid = {
  measures: [
    "Ordersbyitem.avgSellerPayment",
    "Ordersbyitem.shipmentCosts",
    "Ordersbyitem.rateFeesCost",
  ],
  dimensions: ["Ordersbyitem.sourceOrder", "Ordersbyitem.paymentStatus"],
  timeDimensions: [
    {
      dimension: "Ordersbyitem.purchaseDate",
    },
  ],
};

export const noPayments30d = {
  measures: [
    "Ordersbyitem.avgSellerPayment",
    "Ordersbyitem.shipmentCosts",
    "Ordersbyitem.rateFeesCost",
  ],
  dimensions: ["Ordersbyitem.sourceOrder", "Ordersbyitem.paymentStatus"],
  timeDimensions: [
    {
      dimension: "Ordersbyitem.purchaseDate",
    },
  ],
};

export const paymentStatus = {
  measures: [
    "Ordersbyitem.avgSellerPayment",
    "Ordersbyitem.shipmentCosts",
    "Ordersbyitem.rateFeesCost",
  ],
  dimensions: ["PaymentStatus.name", "Ordersbyitem.sourceOrder"],
  timeDimensions: [
    {
      dimension: "Ordersbyitem.purchaseDate",
    },
  ],
};

export const paymentStatusAvailable = {
  measures: [
    "Ordersbyitem.avgSellerPayment",
    "Ordersbyitem.shipmentCosts",
    "Ordersbyitem.rateFeesCost",
  ],
  dimensions: ["Ordersbyitem.sourceOrder"],
  timeDimensions: [
    {
      dimension: "Ordersbyitem.purchaseDate",
    },
  ],
};

export const itemStatus = {
  measures: [
    "Ordersbyitem.avgSellerPayment",
    "Ordersbyitem.avgRentTotal",
    "Ordersbyitem.shipmentCosts",
    "Ordersbyitem.rateFeesCost",
  ],
  dimensions: [
    "Ordersbyitem.publishingIdentifier",
    "Ordersbyitem.itemStatus",
    //"Ordersbyitem.product",
    //"Ordersbyitem.prodCostStatus",
  ],
  timeDimensions: [
    {
      dimension: "Ordersbyitem.purchaseDate",
    },
  ],
};

export const statusQueryFilters = {
  measures: [
    "Ordersbyitem.publishingIdentifierCount",
    "Ordersbyitem.avgSellerPayment",
    "Ordersbyitem.avgRentTotal",
    "Ordersbyitem.shipmentCosts",
    "Ordersbyitem.rateFeesCost",
  ],
  dimensions: [
    "Ordersbyitem.itemStatus",
    "Ordersbyitem.logisticType",
    "Ordersbyitem.sourceType",
    "Ordersbyitem.publishingIdentifier",
    "Ordersbyitem.paymentStatus",
  ],
  timeDimensions: [
    {
      dimension: "Ordersbyitem.purchaseDate",
    },
  ],
  filters: [
    {
      member: "Ordersbyitem.itemType",
      operator: "equals",
      values: ["Orden", "Publicación"],
    },
    {
      member: "Ordersbyitem.paymentStatus",
      operator: "notEquals",
      values: ["noinformation"],
    },
  ],
};

export const logisticStatus = {
  measures: [
    "Ordersbyitem.avgSellerPayment",
    "Ordersbyitem.avgRentTotal",
    "Ordersbyitem.shipmentCosts",
    "Ordersbyitem.rateFeesCost",
  ],
  dimensions: [
    "Ordersbyitem.logisticType",
    "Ordersbyitem.product",
    //"Ordersbyitem.prodCostStatus",
  ],
  timeDimensions: [
    {
      dimension: "Ordersbyitem.purchaseDate",
    },
  ],
};

export const shippingType = {
  measures: ["Ordersbyitem.orders"],
  timeDimensions: [
    {
      dimension: "Ordersbyitem.purchaseDate",
    },
  ],
};

export const orderStatus = {
  measures: ["Ordersbyitem.orders"],
  timeDimensions: [
    {
      dimension: "Ordersbyitem.purchaseDate",
    },
  ],
};

export const marketplaces = {
  measures: [
    "Ordersbyitem.avgSellerPayment",
    "Ordersbyitem.avgRentTotal",
    "Ordersbyitem.shipmentCosts",
    "Ordersbyitem.rateFeesCost",
    "Ordersbyitem.publishingIdentifierCount",
  ],
  dimensions: [
    "SourceType.name",
    "Ordersbyitem.publishingIdentifier",
    //"Ordersbyitem.product",
    //"Ordersbyitem.prodCostStatus",
  ],
  timeDimensions: [
    {
      dimension: "Ordersbyitem.purchaseDate",
    },
  ],
};

export const orders = {
  measures: ["Ordersbyitem.units", "Ordersbyitem.sales", "Ordersbyitem.price"],
  dimensions: [
    "Ordersbyitem.orderId",
    //"ItemStatus.name",
    "Ordersbyitem.itemStatus",
    "Product.codproduct",
    "Ordersbyitem.purchaseDate",
    //"Ordersbyitem.prodCostStatus",
  ],
  timeDimensions: [
    {
      dimension: "Ordersbyitem.purchaseDate",
    },
  ],
  order: { "Ordersbyitem.sales": "desc" },
};

export const publicationDataQuery = (config, identifier) => ({
  dimensions: ["Ordersbyitem.sourceType", "Source.name", "Publication.name"],
  timeDimensions: [{ ...getTimeDimension(config)[0] }],
  filters: [
    {
      member: "Ordersbyitem.publishingIdentifier",
      operator: "equals",
      values: [identifier],
    },
  ],
});

export const publicationIndicatorQuery = (config, filtersItem) => {
  const { identifier, pubId } = filtersItem;
  return {
    measures: [
      "Ordersbyitem.units",
      "Ordersbyitem.sales",
      "Ordersbyitem.expenses",
      "Ordersbyitem.sellerPayment",
      "Ordersbyitem.avgSellerPayment",
      "Ordersbyitem.prodCost",
      "Ordersbyitem.costTotal", // -> Costo total
      "Ordersbyitem.renTotal", // -> Rentabilidad total
      "Ordersbyitem.avgRentTotal", // -> % Rentabilidad total
    ],
    dimensions: [
      "Ordersbyitem.publishingIdentifier" /* , "Ordersbyitem.product" */,
    ],
    filters: [
      {
        member: "Ordersbyitem.publishingIdentifier", //"Ordersbyitem.product",
        operator: "equals",
        values: [identifier],
      },
      /*  {
        member: "Ordersbyitem.publishingIdentifier",
        operator: "equals",
        values: [pubId],
      }, */
      /*  {
        member: "Ordersbyitem.paymentStatus",
        operator: "notEquals",
        values: ["noinformation"],
      }, */
    ],
    timeDimensions: [{ ...getTimeDimension(config)[0] }],
  };
};

export const publicationDetailQuery = (config, identifier, sourceType) => {
  return {
    dimensions: [
      "LogisticType.name",
      "Product.image",
      "Category.name",
      "Brand.name",
      "Product.codproduct",
    ],
    filters: [
      {
        member: "Ordersbyitem.publishingIdentifier", //"Ordersbyitem.product",
        operator: "equals",
        values: [identifier],
      },
      {
        member: "Ordersbyitem.sourceType",
        operator: "equals",
        values: [sourceType],
      },
    ],
    timeDimensions: [{ ...getTimeDimension(config)[0] }],
  };
};
export const profitability = {
  measures: ["Ordersbyitem.avgRentTotal"],
  dimensions: ["Ordersbyitem.product" /* "Ordersbyitem.prodCostStatus"*/],
  timeDimensions: [
    {
      dimension: "Ordersbyitem.purchaseDate",
    },
  ],
};

export const HIDE_PRODUCTS_COLUMNS = {
  "Product.name": true,
  "Product.category": false,
  "Product.codproduct": false,
  "Product.publishingIdentifier": false,
  "Ordersbyitem.price": true,
  "Ordersbyitem.orderId": false,
  "Ordersbyitem.units": true,
  "Ordersbyitem.sales": true,
};

export const getUnitsSimpleCount = {
  measures: [
    "Ordersbyitem.publishingIdentifierCount",
    "Ordersbyitem.avgSellerPayment",
    "Ordersbyitem.avgRentTotal",
    "Ordersbyitem.shipmentCosts",
    "Ordersbyitem.rateFeesCost",
    "Ordersbyitem.ordersWithOneUnit",
    "Ordersbyitem.ordersWithTwoOrMoreUnits",
  ],
  timeDimensions: [
    {
      dimension: "Ordersbyitem.purchaseDate",
    },
  ],
  filters: [
    {
      member: "Ordersbyitem.itemType",
      operator: "equals",
      values: ["Orden", "Publicación"],
    },
    {
      member: "Ordersbyitem.paymentStatus",
      operator: "notEquals",
      values: ["noinformation"],
    },
  ],
};
