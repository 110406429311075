import React, { useEffect, useState } from "react";

import Settings from "@mui/icons-material/Settings";
import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";

import WivoButton from "components/WivoButton/Button";
import { hashHistory } from "react-router";
import { useSelector } from "react-redux";
import { useIntegrationData } from "containers/Marketplaces/useIntegrationData";
import { DATA_CY } from "utils/app/cypressConstants";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    zIndex: 0,
    right: "-0.1rem",
    top: 17,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    background: "#F22C36",
  },
}));

const { COMPONENTS } = DATA_CY;

export function IntegrationSection() {
  const userIdentity = useSelector((state) => state.get("userIdentity"));
  const integration = useSelector((state) => state.get("integration"));
  const { sourceError, reloadIntegrationData } = useIntegrationData();
  const route = useSelector((state) => state.get("route"));
  const [color, setColor] = useState("#78909C");

  const { accountData } = userIdentity;
  const tenant = accountData?.tenant;

  const handleIntegationClick = () => {
    hashHistory.push("/marketplaces/accounts");
    window.document.title = "Integraciones";
  };

  useEffect(() => {
    const { pathname } = route.get("locationBeforeTransitions");
    if (pathname === "/marketplaces/accounts") return setColor("#3770EF");
    return setColor("#78909C");
  }, [route]);

  useEffect(() => {
    reloadIntegrationData(tenant);
  }, [integration, tenant]);

  return (
    <>
      <StyledBadge badgeContent={sourceError} id={`source-error-badge`}>
        <WivoButton
          data_cy={COMPONENTS.navIntegrations}
          text
          label={"Integraciones"}
          startIcon={<Settings />}
          handleOnClick={handleIntegationClick}
          sx={{
            color,
            fontSize: "0.78rem",
            display: { xs: "none", sm: "none", md: "flex" },
          }}
        />
        <IconButton
          sx={{ color: "#78909C", display: { sm: "flex", md: "none" } }}
          component="label"
          onClick={handleIntegationClick}
        >
          <Settings />
        </IconButton>
      </StyledBadge>
    </>
  );
}
