import * as CONST from "./constant";
import menuItems from "utils/app/menuItems";

const initialState = { ...menuItems };

function reducer(appBarState = initialState, { type, payload }) {
  switch (type) {
    case CONST.LOAD_APP_BAR:
      return payload;
    case CONST.UPDATE_APP_BAR:
      return { ...appBarState, ...payload };
    case CONST.CLEAR_APP_BAR:
      return {};
    default:
      return appBarState;
  }
}

export default reducer;
