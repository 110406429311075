import {
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import parse from "html-react-parser";

import styles from "./styles";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import WivoButton from "components/WivoButton/Button";
import { PLAN_MANAGEMENT, TENANT_STATUS } from "utils/app/appConstants";
import { useSelector } from "react-redux";

const { ACTIVATE_TRIAL } = PLAN_MANAGEMENT;

export function PremiumModal({
  action,
  open,
  close,
  title,
  subtitle,
  features = [],
  cta,
  onCTA,
}) {
  const userIdentity = useSelector((state) => state.get("userIdentity"));
  const { accountData } = userIdentity;
  const tenant_status = accountData?.properties?.tenant_status;
  if (tenant_status === TENANT_STATUS.ONBOARDING) return null;
  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle id="premium-dialog-title" sx={styles.dialogTitle}>
        <div style={styles.titleSection}>
          <h2
            style={{
              marginBottom: 0,
              marginTop: 0,
              textAlign: "center",
              fontWeight: 600,
            }}
          >
            {null}
          </h2>
          <CloseIcon
            fontSize="small"
            onClick={close}
            style={styles.closeIcon}
          />
        </div>
      </DialogTitle>
      <DialogContent
        style={{ height: "auto", padding: "0 3.5em 1.5em" }}
        data-cy="premium-modal"
      >
        <h2
          style={{
            marginBottom: "1em",
            marginTop: 0,
            textAlign: "center",
            fontWeight: 600,
          }}
        >
          {title}
        </h2>
        <p className="body2" style={styles.subtitle}>
          {parse(subtitle)}
        </p>
        {action === ACTIVATE_TRIAL && (
          <span className="body2" style={styles.subtitle}>
            {` Tendrás acceso a:`}
          </span>
        )}
        <List>
          {features.map((feature) => (
            <ListItem disablePadding key={feature}>
              <ListItem sx={{ paddingLeft: 0 }}>
                <ListItemIcon sx={{ minWidth: 0, marginRight: "1em" }}>
                  <CheckCircleOutline
                    sx={{
                      "&.MuiSvgIcon-root": {
                        opacity: 0.7,
                        fontSize: "1.1em",
                      },
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={feature}
                  sx={{ color: "#5b717b", fontFamily: "IBM Plex Sans" }}
                />
              </ListItem>
            </ListItem>
          ))}
        </List>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1.5em",
            flexDirection: "column",
            rowGap: "0.5em",
          }}
        >
          <WivoButton
            label={cta}
            sx={{ height: "3em" }}
            handleOnClick={() => onCTA(action)}
          />
          <a
            href={"https://www.wivoanalytics.com/planes"}
            target="_blank"
            rel="noreferrer"
            style={{ marginTop: "0.5em" }}
          >
            <small>Ver planes</small>
          </a>
        </div>
      </DialogContent>
    </Dialog>
  );
}
