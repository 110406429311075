import React, { useEffect, useState } from "react";

import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import Close from "@mui/icons-material/Close";
import { hashHistory } from "react-router";
import Grid from "@mui/material/Grid";

import { REPORTS_TYPES } from "utils/reports/reportsConstants";
import AddReport from "components/AddReport/AddReport";
import { localization } from "localization/es";
import styles from "./styles";

const CreateReport = (props) => {
  const { handleClose, open } = props;
  const {
    REPORTS: { NEW_REPORT_TYPE },
  } = localization;
  const [openState, setOpen] = useState(false);

  const handleOnClick = (report) => {
    handleClose();
    return hashHistory.push(`/reports/${report.key}/new`);
  };

  useEffect(() => {
    setOpen(open);
  }, [open]);

  const handleClosedialog = () => {
    handleClose();
    setOpen(false);
  };
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={openState}
      onClose={handleClosedialog}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div
        data-intercom-target="area-create-report"
        style={{ padding: "0.5rem" }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "var(--blue-gray-5)" }}
        >
          <div style={styles.title}>
            <span className="body1">{NEW_REPORT_TYPE}</span>
            <Close
              fontSize="small"
              onClick={handleClosedialog}
              style={{ cursor: "pointer" }}
            />
          </div>
        </DialogTitle>
        <DialogContent style={styles.content}>
          <div style={styles.root}>
            <Grid container direction="row" rowSpacing={5} columnSpacing={3}>
              {REPORTS_TYPES.map((report) => (
                <Grid item xs={12} sm={12} md={4} key={report.key}>
                  <AddReport
                    data_cy={report.data_cy}
                    id={report.key}
                    icon={report.icon}
                    title={report.title}
                    subtitle={report.subtitle}
                    onActionClick={() => handleOnClick(report)}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default CreateReport;
