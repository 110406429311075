import { basicHeader, makeRequest } from "services/helper";
import * as REQUEST from "./endpoints";

/**
 *
 * @param {*} tenant
 * @returns report user data
 */
export function getAccountData(tenant, basic) {
  const options = {
    method: "GET",
    headers: basicHeader,
  };

  return makeRequest(REQUEST.getAccountData(tenant, basic), options);
}

export function registerNewCliente(clientData) {
  const options = {
    method: "POST",
    headers: basicHeader,
    body: JSON.stringify(clientData),
  };

  return makeRequest(REQUEST.registerClient(), options);
}

export function updateCliente(clientData, tenant) {
  const options = {
    method: "PATCH",
    headers: basicHeader,
    body: JSON.stringify(clientData),
  };

  return makeRequest(REQUEST.getAccountData(tenant), options);
}

export function registerUserDataAut0(clientData) {
  const options = {
    method: "POST",
    headers: basicHeader,
    body: JSON.stringify(clientData),
  };

  return makeRequest(REQUEST.registerUserDataAut0(), options);
}

export function getIntegrationData(tenant, from) {
  const options = {
    method: "GET",
    headers: { ...basicHeader, tenant },
  };

  return makeRequest(REQUEST.getIntegrationData(from), options);
}

export function registerSource(tenant, data) {
  const options = {
    method: "POST",
    headers: { ...basicHeader, tenant },
    body: JSON.stringify(data),
  };

  return makeRequest(REQUEST.registerSource(), options);
}

export function getSources(tenant) {
  const options = {
    method: "GET",
    headers: { ...basicHeader, tenant },
  };

  return makeRequest(REQUEST.getSources(), options);
}

export function getSourceInstanceData(tenant, identifier) {
  const options = {
    method: "GET",
    headers: { ...basicHeader, tenant },
  };

  return makeRequest(REQUEST.managamentSourceInstance(identifier), options);
}

export function updateSourceInstance(tenant, data, identifier) {
  const options = {
    method: "PUT",
    headers: { ...basicHeader, tenant },
    body: JSON.stringify(data),
  };

  return makeRequest(REQUEST.managamentSourceInstance(identifier), options);
}

export function deleteSourceInstance(tenant, identifier) {
  const options = {
    method: "DELETE",
    headers: { ...basicHeader, tenant },
  };

  return makeRequest(REQUEST.managamentSourceInstance(identifier), options);
}

export function inviteUser(invitation) {
  const options = {
    method: "POST",
    headers: basicHeader,
    body: JSON.stringify(invitation),
  };

  return makeRequest(REQUEST.inviteUser(), options);
}

export function activateTrialRequest(trial) {
  const options = {
    method: "POST",
    headers: basicHeader,
    body: JSON.stringify(trial),
  };

  return makeRequest(REQUEST.activateTrial(), options);
}

export function changeTenantProperty(payload) {
  const options = {
    method: "POST",
    headers: basicHeader,
    body: JSON.stringify(payload),
  };

  return makeRequest(REQUEST.tenantProperties(), options);
}

export function setThresholds(thresholds, tenant) {
  const options = {
    method: "PUT",
    headers: basicHeader,
    body: JSON.stringify(thresholds),
  };

  return makeRequest(REQUEST.thresholds(tenant), options);
}

export function updateSourceStatus(identifier, status) {
  const options = {
    method: "PUT",
    headers: basicHeader,
    body: JSON.stringify(status),
  };

  return makeRequest(REQUEST.sourceStatus(identifier), options);
}

export function updateSourceAlias(source_identifier, alias, source_parameters) {
  const options = {
    method: "POST",
    headers: basicHeader,
    body: JSON.stringify(
      source_parameters
        ? { source_identifier, alias, source_parameters }
        : { source_identifier, alias }
    ),
  };

  return makeRequest(REQUEST.updateAlias(), options);
}
