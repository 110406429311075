import * as CONST from "./constant";

export function loadOnBoardingData(data) {
  return {
    type: CONST.LOAD_ONBOARDING_DATA,
    payload: data,
  };
}

export function updateOnBoardingData(newData) {
  return {
    type: CONST.UPDATE_ONBOARDING_DATA,
    payload: newData,
  };
}
