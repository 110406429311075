import { useEffect, useRef, useState } from "react";

import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import {
  MenuItem,
  ClickAwayListener,
  Popper,
  Grow,
  Paper,
  MenuList,
  Divider,
  Typography,
  Zoom,
} from "@mui/material";
import { hashHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import CreateReport from "containers/Home/CreateReport/CreateReport";
import { NewReport } from "../NewReport/NewReport";
import { updateReportConfig } from "redux/ReportsConfig/actions";
import styles from "./styles";
import { updateAppBar } from "redux/AppBar/actions";
import { activateItemMenu, isAvailableForPlan } from "utils/utils";
import { updateAppState } from "redux/App/actions";
import moment from "moment";

import {
  PLAN_MANAGEMENT,
  TENANT_STATUS,
  TRIALS,
  TRIAL_RESTART_PERIOD,
  UPGRADE,
} from "utils/app/appConstants";
import LightTooltip, {
  LightTooltipContent,
} from "components/LightTooltip/LightTooltip";
import { localization } from "localization/es";
import PremiumIcon from "components/Icons/PremiumIcon";
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: "-0.7rem",
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    background: "#F22C36",
    //color: "#F22C36",
  },
}));

const { ACTIVATE_TRIAL, UPGRADE_PLAN } = PLAN_MANAGEMENT;
const { APP } = localization;
export function AppMenuItem({ itemKey, menu, sourceError, ...rest }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const appBar = useSelector((state) => state.get("appBar"));
  const app = useSelector((state) => state.get("app"));
  const [currentPlan, setPlan] = useState("free");
  const userIdentity = useSelector((state) => state.get("userIdentity"));
  const integrationsStatus = useSelector((state) =>
    state.get("integrationsStatus")
  );
  const { hasAnyIntegration } = integrationsStatus;

  const { blockedRoute } = app;

  const anchorRef = useRef(null);
  const dispatch = useDispatch();

  const { accountData } = userIdentity;

  const { trial, plan, tenant_status } = accountData?.properties || {};
  const { type, start_date, duration } =
    (trial && Array.isArray(trial) ? trial[0] : trial) || {};

  const handleToggle = () => {
    return setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    return setOpen(false);
  };

  const handleItemMenuClick = (event) => {
    if (isAvailableForPlan(currentPlan, menu.plan)) {
      if (menu.subItems) {
        return handleToggle();
      } else return goTo(menu.rootPath);
    } else {
      if (tenant_status === TENANT_STATUS.ONBOARDING) return;
      let trialAvailable = currentPlan === "free" && !trial;
      if (!trialAvailable && currentPlan === "free") {
        let now = moment(new Date());
        let trialStart = moment(start_date, "YYYY-MM-DD”");
        let days = now.diff(trialStart, "days");
        if (days > TRIAL_RESTART_PERIOD) trialAvailable = true;
      }
      const { title, subtitle, features, cta } = trialAvailable
        ? TRIALS.PRO
        : UPGRADE.PRO;
      const planManagement = {
        action: trialAvailable ? ACTIVATE_TRIAL : UPGRADE_PLAN,
        open: true,
        title,
        subtitle,
        features,
        cta,
      };
      dispatch(updateAppState({ planManagement }));
    }
  };

  useEffect(() => {
    if (trial) {
      let now = moment(new Date());
      let trialStart = moment(start_date, "YYYY-MM-DD”");
      let days = now.diff(trialStart, "days");
      const remainingDays = duration - days;

      if (remainingDays > 0) setPlan(type.toLowerCase());
      else setPlan(plan?.toLowerCase() || "free");
    } else setPlan(plan?.toLowerCase() || "free");
  }, [trial, plan]);

  const handleOnSubItemClick = (event, id, route, plan) => {
    handleClose(event);
    if (isAvailableForPlan(currentPlan, plan)) {
      return goTo(route, id);
    } else {
      if (tenant_status === TENANT_STATUS.ONBOARDING) return;
      let trialAvailable = currentPlan === "free" && !trial;
      if (!trialAvailable && currentPlan === "free") {
        let now = moment(new Date());
        let trialStart = moment(start_date, "YYYY-MM-DD”");
        let days = now.diff(trialStart, "days");
        if (days > TRIAL_RESTART_PERIOD) trialAvailable = true;
      }
      const { title, subtitle, features, cta } = trialAvailable
        ? TRIALS.PRO
        : UPGRADE.PRO;
      const planManagement = {
        action: trialAvailable ? ACTIVATE_TRIAL : UPGRADE_PLAN,
        open: true,
        title,
        subtitle,
        features,
        cta,
      };
      dispatch(updateAppState({ planManagement }));
    }
  };

  const getItemTitle = (id) => {
    const staticSubItemIds = {
      keywords: "Categorías y Búsquedas",
      publishing: "Edición de Publicaciones",
    };
    const activeSubItem = menu.subItems.find((item) => item.id === id);
    return staticSubItemIds[id] ? staticSubItemIds[id] : activeSubItem.name;
  };
  const goTo = (path, subItemId) => {
    if (rest.location.pathname === blockedRoute) {
      dispatch(updateAppState({ showRouteBlockedAlert: true, goNext: path }));
      return;
    }
    let title = subItemId ? getItemTitle(subItemId) : menu.name;
    const host = window.location.hostname.split(".");
    window.document.title = `${title} | ${host[1]}`;
    dispatch(
      updateReportConfig({
        filters: [],
        quickFilters: {},
        lastFilterSelected: [],
        dashboardMetric: "sales",
        loadingConfig: true,
        offset: 0,
        isSearch: false,
        searchCriteria: undefined,
        reportType: undefined,
        filtersLabels: {},
        filtersKeys: {},
      })
    );
    dispatch(updateAppBar(activateItemMenu(appBar, itemKey, subItemId)));
    hashHistory.push(path);
  };

  const color = menu.active ? "#3770EF" : "#78909C";
  return (
    <>
      <StyledBadge
        key={itemKey}
        badgeContent={sourceError}
        invisible={itemKey !== "sources"}
        data-intercom-target={`menu-${itemKey}`}
        id={`menu-${itemKey}`}
      >
        <LightTooltip
          TransitionComponent={Zoom}
          title={
            isAvailableForPlan(currentPlan, menu.plan) ? (
              ""
            ) : (
              <LightTooltipContent
                title={APP.UPGRADE_TITLE}
                description={APP.UPGRADE_DESCRIPTION}
              />
            )
          }
          placement="bottom-start"
        >
          <Typography
            className="body2"
            sx={{
              ...styles.menu,
              "&:hover": {
                color: "#3770EF",
              },
              color,
            }}
            data-intercom-target={`menu-${itemKey}`}
            onClick={(e) => handleItemMenuClick(e)}
            ref={anchorRef}
            id={`menu-${itemKey}`}
            aria-controls={open ? `composition-menu-${itemKey}` : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            data-cy={menu["data-cy"]}
          >
            {menu.plan !== "free" && (
              <span
                style={{
                  marginRight: "0.5em",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PremiumIcon />
                </div>
                {/*<img
                  style={{ height: "0.8em", opacity: 0.4 }}
                  src="/images/premium-quality.svg"
                  alt=""
                />*/}
              </span>
            )}
            {menu.name}
            {menu.subItems && <ArrowDropDown />}

            {/*  {itemKey === "publications" && (
              <div style={{ marginLeft: "0.5em" }}>
                <NewTag tag="beta" />
              </div>
            )} */}
          </Typography>
        </LightTooltip>
      </StyledBadge>
      <Popper
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <div style={{ marginTop: "1em", paddingTop: "5px" }}>
                  {itemKey === "reports" && (
                    <NewReport
                      handleOnClick={(e) => {
                        handleClose(e);
                        setOpenDialog(true);
                      }}
                    />
                  )}
                  <MenuList
                    autoFocusItem={open}
                    id={`composition-menu-${itemKey}`}
                    aria-labelledby={`composition-button-${itemKey}`}
                    sx={styles.menuList}
                  >
                    {menu?.subItems &&
                      menu.subItems.map((item, index) => {
                        const {
                          id,
                          route,
                          active,
                          name,
                          plan,
                          "data-cy": data_cy,
                        } = item;
                        return (
                          <div
                            key={id}
                            onClick={(e) =>
                              handleOnSubItemClick(e, id, route, plan)
                            }
                          >
                            <MenuItem
                              key={id}
                              sx={active ? styles.menuItemActive : {}}
                              style={{ justifyContent: "space-between" }}
                            >
                              <div style={styles.item} data-cy={data_cy}>
                                {name}
                              </div>
                              {/*    <Badge
                                badgeContent={sourceError}
                                invisible={id !== "accounts"}
                                sx={{
                                  "& .MuiBadge-badge": {
                                    background: "#F22C36",
                                    color: "#FFF",
                                    //mr: 1,
                                  },
                                }}
                              /> */}
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  columnGap: "0.5em",
                                }}
                              >
                                {item.plan !== "free" && (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <PremiumIcon />
                                  </div>
                                )}
                                {/*   {item.id === "profitability" && (
                                  <NewTag tag="beta" />
                                )} */}
                              </div>
                            </MenuItem>
                            <Divider
                              variant="middle"
                              sx={{
                                display:
                                  index < menu.subItems.length - 1
                                    ? "block"
                                    : "none",
                              }}
                            />
                          </div>
                        );
                      })}
                  </MenuList>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <CreateReport
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
      />
    </>
  );
}
