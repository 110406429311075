import { useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

import { Box } from "@mui/system";

import { LOGOUT_REDIRECT, TENANT_STATUS } from "utils/app/appConstants";
import { clearLocalStorage } from "utils/app/localStorage";
import { AppMenuMobil } from "components/Menu/AppMenuMobile";
import { localization } from "localization/es";
import styles from "./styles";
import { useTheme } from "@mui/styles";
import moment from "moment";
import Modal from "components/Modal/Modal";
import { updateUserIdentity } from "redux/UserIdentity/actions";
import { updateOnBoardingData } from "redux/OnBoarding/actions";
import { initialState } from "redux/OnBoarding/reducer";
import { hashHistory } from "react-router";
import { UserSection } from "./UserSection";
import { PlanSection } from "./PlanSection";
import { AccountsSection } from "./AccountsSection";
import { TrialSection } from "./TrialSection";
import { loadIntegrationValidationData } from "redux/Integration/actions";

export function ProfileMenu({ handleClose }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const identity = useSelector((state) => state.get("userIdentity"));
  const onBoardingState = useSelector((state) => state.get("onBoarding"));
  const [currentPlan, setPlan] = useState("free");
  const { logout } = useAuth0();
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();

  const { LOGIN } = localization;
  const { isDemo, accountData, userData } = identity;
  const { demoEnabled } = isDemo;
  const email = userData?.email;
  const { tenantsList = [] } = userData ?? {};
  const currentAccount = accountData?.id;
  const client = accountData?.client;
  const tenant_status = accountData?.properties?.tenant_status;
  const { trial, plan } = accountData?.properties || {};
  const { type, start_date, duration } =
    (trial && Array.isArray(trial) ? trial[0] : trial) || {};
  const onBoardingFinish = onBoardingState?.finish;

  const clientName = useMemo(() => {
    return <h4 style={{ margin: 0, textTransform: "capitalize" }}>{client}</h4>;
  }, [client]);

  const { ENABLED, LOADING } = TENANT_STATUS;

  const closeModal = () => {
    setOpenModal(false);
  };
  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (trial) {
      let now = moment(new Date());
      let trialStart = moment(start_date, "YYYY-MM-DD”");
      let days = now.diff(trialStart, "days");
      const remainingDays = duration - days;

      if (remainingDays > 0) setPlan(type.toLowerCase());
      else setPlan(plan?.toLowerCase() || "free");
    } else setPlan(plan?.toLowerCase() || "free");
  }, [trial, plan]);

  const customLogout = (cleanStorage) => {
    handleClose();
    setTimeout(function () {
      setTimeout(function () {
        clearLocalStorage();
        logout({ returnTo: LOGOUT_REDIRECT });
      }, 1000);
    }, 1);
  };

  const newAccount = () => {
    dispatch(updateUserIdentity({ accountData: {} }));
    dispatch(
      updateOnBoardingData({
        ...initialState,
        finish: false,
        pending: ["tenant_name", "country"],
      })
    );

    dispatch(loadIntegrationValidationData({}));
    hashHistory.push("/onBoarding/stage/start");
  };

  return (
    <>
      <List component="nav" sx={styles.listRoot}>
        <ListItem alignItems="flex-start" sx={styles.listItemRoot}>
          <UserSection client={clientName} email={email} />
        </ListItem>
        <Divider component="li" />
        <PlanSection
          open={open}
          currentPlan={currentPlan}
          handleClick={handleClick}
        />
        <Divider component="li" />
        {!demoEnabled && tenant_status === ENABLED && (
          <>
            <Divider component="li" />
            <TrialSection handleClose={handleClose} />
            <Divider component="li" />
          </>
        )}
        {isMobile && onBoardingFinish && (
          <Box sx={{ display: { xs: "block", sm: "block", md: "block" } }}>
            <AppMenuMobil onItemClick={handleClose} />
            <Divider component="li" />
          </Box>
        )}
        <AccountsSection
          tenantsList={tenantsList}
          currentAccount={currentAccount}
          tenant_status={tenant_status}
          handleClose={handleClose}
          setOpenModal={setOpenModal}
        />
        <ListItem
          button
          onClick={customLogout}
          sx={{ ...styles.listItemRoot, mt: 1 }}
        >
          <ListItemText
            style={{ color: "var(--blue-gray-5)", fontWeight: 500 }}
            primary={
              <span style={styles.logOutroot}>
                <LogoutOutlinedIcon sx={styles.newAccountIcon} />
                <span className="body1">{LOGIN.LOGOUT}</span>
              </span>
            }
          />
        </ListItem>
      </List>
      <Modal
        open={openModal}
        handleClose={closeModal}
        okAction={newAccount}
        type={"confirm"}
        title={`Crear cuenta adicional`}
        label={localization.ACTIONS.CREATE_ACCOUNT}
        message={
          <span className="body2" style={{ lineHeight: "1.5em" }}>
            Estás seguro que deseas crear una cuenta adicional en Wivo
          </span>
        }
      />
    </>
  );
}
