import { COLORS } from "utils/app/appConstants";

const PremiumIcon = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      height: "1em",
      //opacity: 0.4,
      //marginLeft: "1em",
      fill: COLORS.YELLOW_BASE, // Cambia el color del SVG a rojo
    }}
  >
    <g>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M2 19h20v2H2v-2zM2 5l5 3 5-6 5 6 5-3v12H2V5z" />
    </g>
  </svg>
);

export default PremiumIcon;
