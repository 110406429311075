/* eslint-disable */
import "whatwg-fetch";
import { ALPACA_URL, URL_BASE, URL_BASE_SOURCE_INTEGRATION } from "./constants";
import { buildUrl } from "services/helper";

/**
 *
 * @param {*} user_email
 * @returns the user data URL
 */
export function getAccountData(tenant, basic) {
  if (basic)
    return buildUrl(
      ALPACA_URL,
      `${URL_BASE}/client/tenant/${tenant}?mode=basic`
    );
  return buildUrl(ALPACA_URL, `${URL_BASE}/client/tenant/${tenant}`);
}

export function registerClient() {
  return buildUrl(ALPACA_URL, `${URL_BASE}/client/tenant`);
}

export function registerUserDataAut0() {
  return buildUrl(ALPACA_URL, `${URL_BASE}/oauth/user`);
}

export function getIntegrationData(from) {
  const params = `?${from}`;
  if (from)
    return buildUrl(ALPACA_URL, `account/signup/integrationsdata${params}`);
  return buildUrl(ALPACA_URL, `account/signup/integrationsdata`);
}

export function registerSource() {
  return buildUrl(ALPACA_URL, `${URL_BASE_SOURCE_INTEGRATION}/source/register`);
}

export function getSources() {
  return buildUrl(ALPACA_URL, `${URL_BASE_SOURCE_INTEGRATION}/source`);
}

export function managamentSourceInstance(identifier) {
  return buildUrl(
    ALPACA_URL,
    `${URL_BASE_SOURCE_INTEGRATION}/source/${identifier}/`
  );
}

export function inviteUser() {
  return buildUrl(ALPACA_URL, `${URL_BASE}/client/invite`);
}

export function activateTrial() {
  return buildUrl(
    ALPACA_URL,
    `${URL_BASE_SOURCE_INTEGRATION}/trial/active_trial`
  );
}

export function tenantProperties() {
  return buildUrl(ALPACA_URL, `account/airflow/tenant/properties`);
}

//account/v3/client/thresholds/ed9f6563-c552-4bc5-a3df-989a0348780b
export function thresholds(tenant) {
  return buildUrl(ALPACA_URL, `account/v3/client/thresholds/${tenant}`);
}

export function sourceStatus(identifier) {
  return buildUrl(ALPACA_URL, `account/airflow/source/status/${identifier}`);
}

export function updateAlias() {
  return buildUrl(ALPACA_URL, `account/v3/client/source/extra-data`);
}