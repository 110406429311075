import { useEffect, useState } from "react";

import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";

import { AppMenuItem } from "./AppMenuItem";
import styles from "./styles";
import { updateAppBar } from "redux/AppBar/actions";
import { activateItemMenu } from "utils/utils";
import { useIntegrationData } from "containers/Marketplaces/useIntegrationData";

export function AppMenu(props) {
  const { sourceError } = useIntegrationData();
  const appBar = useSelector((state) => state.get("appBar"));
  const route = useSelector((state) => state.get("route"));
  const [menuList, setMenuList] = useState({ ...appBar });

  const dispatch = useDispatch();

  const routePath = route
    .get("locationBeforeTransitions")
    .pathname.split("/")
    .filter((path) => path !== "");

  useEffect(() => {
    const { action } = route.get("locationBeforeTransitions");
    if (action === "POP") {
      let itemKey = routePath[0];
      let subItemId = routePath[1];
      if (
        subItemId === "dashboard" &&
        itemKey !== "keywords" &&
        itemKey !== "publishing"
      ) {
        itemKey = routePath[1];
        subItemId = routePath[0];
      }

      dispatch(updateAppBar(activateItemMenu(appBar, itemKey, subItemId)));
    }
  }, [route]);

  useEffect(() => {
    setMenuList({ ...appBar });
  }, [appBar]);

  return (
    <Box sx={styles.root}>
      {Object.entries(menuList).map(([key, menu]) => (
        <AppMenuItem
          {...props}
          key={key}
          itemKey={key}
          menu={menu}
          sourceError={sourceError}
        />
      ))}
    </Box>
  );
}
