/* eslint-disable */
import "whatwg-fetch";
import { PUBLISHING_URL } from "../constants";
import { buildUrl } from "services/helper";

export function keywords(queryParams) {
  return buildUrl(
    PUBLISHING_URL,
    queryParams ? `keywords?${queryParams}` : `keywords`
  );
}

export function keywordById(keywordId) {
  return buildUrl(PUBLISHING_URL, `keywords/${keywordId}`);
}

export function keywordsSubscriptions() {
  return buildUrl(PUBLISHING_URL, `subscriptions/keywords`);
}

export function publishing() {
  return buildUrl(PUBLISHING_URL, `publishing`);
}

export function publishingSeller() {
  return buildUrl(PUBLISHING_URL, `publishing/seller`);
}

export function findOrCreatePublishing() {
  return buildUrl(PUBLISHING_URL, `publishing/findOrCreate`);
}

export function removePublishing(publishingId) {
  return buildUrl(PUBLISHING_URL, `publishing/${publishingId}`);
}

export function publishingById(publishingId) {
  return buildUrl(PUBLISHING_URL, `publishing/${publishingId}`);
}

export function bulkPublishingSubscriptions() {
  return buildUrl(PUBLISHING_URL, `subscriptions/publishing/bulk`);
}

export function publishingSubscriptions() {
  return buildUrl(PUBLISHING_URL, `subscriptions/publishing`);
}

export function removePublishingSubscriptions(id) {
  return buildUrl(PUBLISHING_URL, `subscriptions/publishing/${id}`);
}

export function removeKeywordSubscriptions(id) {
  return buildUrl(PUBLISHING_URL, `subscriptions/keywords/${id}`);
}

export function trial() {
  return buildUrl(PUBLISHING_URL, `plan/trial`);
}

export function planUpgrade() {
  return buildUrl(PUBLISHING_URL, `plan/upgrade`);
}

export function updateMeasureOwn_publication(id, tenant, pId) {
  return buildUrl(
    PUBLISHING_URL,
    `measurements/publications/?sub=${id}&t=${tenant}&pId=${pId}`
  );
}

export function updateSuscriptionOwn_publication(id) {
  return buildUrl(PUBLISHING_URL, `subscriptions/publishing/${id}`);
}

export function updateKeywordsMeasureOwn_publication(
  id,
  tenant,
  seller,
  sourcetype
) {
  return buildUrl(
    PUBLISHING_URL,
    `measurements/keywords/?sub=${id}&t=${tenant}&s=${seller}&st=${sourcetype}`
  );
}

export function updateKeywordsSuscriptionOwn_publication(id) {
  return buildUrl(PUBLISHING_URL, `subscriptions/keywords/${id}`);
}
