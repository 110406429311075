import * as CONST from "./constant";

const initialState = {};

function reducer(data = initialState, { type, payload }) {
  switch (type) {
    case CONST.LOAD_INTEGRATED_ACCOUNTS:
      return payload;
    case CONST.UPDATE_INTEGRATED_ACCOUNTS:
      return { ...data, ...payload };
    case CONST.UPDATE_INTEGRATED_ACCOUNT_STATUS:
      const { sourceKey, identifier, status } = payload;
      const newData = {
        ...data,
        [sourceKey]: {
          ...data[sourceKey],
          accounts: data[sourceKey].accounts.map((account) => {
            if (account.identifier === identifier) {
              return { ...account, source_status: status };
            }
            return account;
          }),
        },
      };
      return newData;
    default:
      return data;
  }
}

export default reducer;
