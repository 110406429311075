/* eslint-disable */
import ENC from "crypto-js/enc-utf8";
import AES from "crypto-js/aes";

const key = "W1Vo3ncryp7";

const encryptJson = (json) => AES.encrypt(JSON.stringify(json), key).toString();

const decryptHashToJson = (hash) => {
  const bytes = AES.decrypt(hash, key);
  return JSON.parse(bytes.toString(ENC));
};

export const saveAccessToken = (token) => {
  try {
    const serializedToken = encryptJson(token);
    localStorage.setItem("kodkod", serializedToken);
  } catch (error) {
    console.error("saveToken Error: ", error);
    return undefined;
  }
};
export const loadAccessToken = () => {
  try {
    const serializedToken = localStorage.getItem("kodkod");
    if (serializedToken === null) {
      return undefined;
    }
    return decryptHashToJson(serializedToken);
  } catch (error) {
    console.error("loadToken Error: ", error);
    return undefined;
  }
};

export const loadUser = () => {
  try {
    const serializedUser = localStorage.getItem("account");
    if (serializedUser === null) {
      return undefined;
    }
    return decryptHashToJson(serializedUser);
  } catch (error) {
    console.error("loadState Error: " + error);
    return undefined;
  }
};

export const saveUser = (user) => {
  try {
    const serializedUser = encryptJson(user);
    localStorage.setItem("account", serializedUser);
  } catch (error) {
    console.error("saveState Error: ", error);
    return undefined;
  }
};

export const clearLocalStorage = () => {
  window.localStorage.removeItem("account");
  window.localStorage.removeItem("kodkod");
};
