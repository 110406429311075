import React, { useEffect, useState } from "react";

import {
  ClickAwayListener,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  TextField,
} from "@mui/material";
import CloseRounded from "@mui/icons-material/CloseRounded";
import SearchRounded from "@mui/icons-material/SearchRounded";

import styles from "./styles";
import WivoButton from "components/WivoButton/Button";
import { useDispatch, useSelector } from "react-redux";
import { generateQueryString } from "utils/utils";
import { updateQueryParams } from "redux/App/actions";
import { DATA_CY } from "utils/app/cypressConstants";

const { COMPONENTS } = DATA_CY;
export default function SearchBasic(props) {
  const {
    size,
    placeholder,
    label,
    variant,
    onSearch,
    disabled,
    defaultCriteria,
    autocomplete,
    hits,
    isMainSearch,
    id,
  } = props;
  const [criteria, setCriteria] = useState(undefined);
  const reportConfig = useSelector((state) => state.get("reportConfig"));
  const [autocompletList, setAutocompleteList] = useState(undefined);
  const [isNewKeyword, setIsNewKeyword] = useState(false);
  const route = useSelector((state) => state.get("route"));
  const dispatch = useDispatch();

  const { loadingConfig } = reportConfig;

  const routeObject = route.get("locationBeforeTransitions");
  const { search } = routeObject?.query;

  useEffect(() => {
    setCriteria(defaultCriteria);
  }, [defaultCriteria]);

  useEffect(() => {
    if (!criteria && isMainSearch && !loadingConfig) {
      setCriteria(search);
      onSearch(search);
    }
  }, [search, loadingConfig]);

  const handleOnChange = (event) => {
    const { value } = event.target;
    if (autocomplete && hits && value !== "") {
      let hitList = hits.filter((hit) =>
        hit.toLowerCase().includes(value.toLowerCase())
      );

      if (!hitList.length) {
        hitList = [value];
        setIsNewKeyword(true);
      } else setIsNewKeyword(false);

      setAutocompleteList(hitList);
    } else setAutocompleteList(undefined);
    setCriteria(value);
  };

  const handleSearch = (criteria) => {
    onSearch(criteria);
    //agregando criteria como queryparams a la url
    if (isMainSearch) {
      const queryFilters = routeObject.query?.filters;
      let query = {};
      if (queryFilters) {
        try {
          const decode = decodeURIComponent(queryFilters);
          const parsed = JSON.parse(decode);
          const coded = encodeURIComponent(JSON.stringify(parsed));

          query = {
            ...routeObject.query,
            filters: coded,
            search: criteria,
          };
        } catch (error) {
          delete routeObject.query?.search;
        }
      } else {
        query = { ...routeObject.query, search: criteria };
      }
      const search = `?${generateQueryString(query)}`;
      dispatch(updateQueryParams({ ...routeObject, query, search }));
    }
  };
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <TextField
        //data-cy={COMPONENTS.search_input}
        fullWidth
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            handleSearch(criteria);
          }
        }}
        disabled={disabled}
        sx={styles.root}
        size={size}
        label={label}
        placeholder={placeholder}
        id={id}
        name={"searchField"}
        value={criteria || ""}
        onChange={handleOnChange}
        inputProps={{
          "data-cy": COMPONENTS.search_input,
        }}
        InputProps={{
          "data-intercom-target": "search",
          endAdornment: (
            <InputAdornment position="end">
              {criteria && (
                <IconButton
                  data-cy={COMPONENTS.search_input_clear}
                  type="submit"
                  aria-label="clean"
                  onClick={() => {
                    setCriteria("");
                    onSearch("");
                    delete routeObject.query?.search;
                    const queryFilters = routeObject.query?.filters;
                    let query = {};
                    if (queryFilters) {
                      try {
                        const decode = decodeURIComponent(queryFilters);
                        const parsed = JSON.parse(decode);
                        const coded = encodeURIComponent(
                          JSON.stringify(parsed)
                        );

                        query = {
                          ...routeObject.query,
                          filters: coded,
                        };
                      } catch (err) {
                        delete routeObject.query?.search;
                      }
                    } else {
                      query = { ...routeObject.query };
                    }

                    //const query = { ...routeObject.query };
                    const search = `?${generateQueryString(query)}`;
                    dispatch(
                      updateQueryParams({ ...routeObject, query, search })
                    );
                  }}
                >
                  <CloseRounded
                    style={{
                      color: "var(--blue-gray-4)",
                      cursor: "pointer",
                      fontSize: "1rem",
                    }}
                  />
                </IconButton>
              )}
              <Divider sx={styles.divider} orientation="vertical" />
              <IconButton
                data-cy={COMPONENTS.search_input_apply}
                disabled={disabled}
                type="submit"
                aria-label="search"
                onClick={() => {
                  handleSearch(criteria);
                }}
              >
                <SearchRounded
                  style={{ color: "var(--blue-gray-4)", cursor: "pointer" }}
                  fontSize="small"
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
        variant={variant}
      />
      {autocomplete && autocompletList && (
        <ClickAwayListener onClickAway={() => setAutocompleteList(undefined)}>
          <Paper
            elevation={3}
            style={{ position: "absolute", zIndex: 1, width: "100%" }}
          >
            <List dense sx={{ width: "100%", bgcolor: "background.paper" }}>
              {autocompletList.map((hit, indx) => {
                return (
                  <ListItem
                    key={hit}
                    disablePadding
                    xs={{ width: "100%" }}
                    secondaryAction={
                      /*  isNewKeyword &&  */ <WivoButton
                        size="small"
                        text
                        color="primary"
                        outlined
                        label={"Subscribirse"}
                        handleOnClick={() => {
                          onSearch(hit);
                          setAutocompleteList(undefined);
                        }}
                      />
                    }
                  >
                    <ListItemButton
                      xs={{ width: "100%" }}
                      onClick={() => {
                        onSearch(autocompletList[indx]);
                        setAutocompleteList(undefined);
                        setCriteria(undefined);
                      }}
                    >
                      <ListItemText
                        id={hit}
                        primary={hit}
                        xs={{ width: "100%" }}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Paper>
        </ClickAwayListener>
      )}
    </div>
  );
}
