const styles = {
  primary: {
    background: "#f22c36",
    ":hover": {
      background: "#f22c36",
      opacity: "0.7",
      color: "#fff",
    },
  },
  secondary: {
    background: "#3770EF",
    ":hover": {
      background: "#3770EF",
      opacity: "0.7",
      color: "#fff",
    },
  },
  default: {
    background: "#78909C",
    ":hover": {
      background: "#78909C",
      opacity: "0.7",
      color: "#fff",
    },
  },
  text: {
    color: "#78909c",
    background: "transparent",
    "&:hover": {
      color: "#3770EF",
    },
  },
  outlined_primary: {
    color: "#f22c36",
    borderColor: "#f22c36",
    ":hover": {
      color: "#f22c36",
      borderColor: "#f22c36",
      opacity: "0.7",
      //color: "#fff",
    },
  },
  outlined_secondary: {
    color: "#3770EF",
    borderColor: "#3770EF",
    ":hover": {
      color: "#3770EF",
      borderColor: "#3770EF",
      opacity: "0.7",
      //color: "#fff",
    },
  },
  outlined_default: {
    color: "#78909C",
    borderColor: "#78909C",
    ":hover": {
      color: "#78909C",
      borderColor: "#78909C",
      opacity: "0.7",
      //color: "#fff",
    },
  },
};
export default styles;
