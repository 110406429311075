import * as CONST from "./constant";

export function loadUserIdentity(identity) {
  return {
    type: CONST.LOAD_USER_IDENTITY,
    payload: identity,
  };
}

export function updateUserIdentity(identity) {
  return {
    type: CONST.UPDATE_USER_IDENTITY,
    payload: identity,
  };
}

export function clearUserIdentity() {
  return {
    type: CONST.CLEAR_USER_IDENTITY,
  };
}
