import { useState, useEffect, useCallback } from "react";

import { Alert } from "@mui/material";
import { useSelector } from "react-redux";

import { TENANT_STATUS, WELCOME_MESSAGE } from "utils/app/appConstants";
import { getSourceInstanceData } from "services/alpaca/request";
import Toast from "components/Toast";
import { localization } from "localization/es";
import { useIntegrationData } from "containers/Marketplaces/useIntegrationData";
import { printKodkodLog } from "utils/utils";
import { useSnackbar } from "notistack";

export function LoadingAlert({ activeBanner }) {
  const {
    SOURCES_MANAGEMENT: { HISTORICAL_LOADING, NEW, OK, REGULAR },
  } = localization;
  const [sourcesLoading, setSourcesLoading] = useState([]);
  const [accountsLoading, setAccountsLoading] = useState([]);
  const userIdentity = useSelector((state) => state.get("userIdentity"));
  const { enqueueSnackbar } = useSnackbar();
  const { reloadIntegrationData } = useIntegrationData();
  const integratedAccounts = useSelector((state) =>
    state.get("integratedAccounts")
  );
  const { accountData } = userIdentity;
  const { tenant_status } = accountData?.properties || {};
  const tenant = accountData?.tenant;
  const [toast, setToast] = useState({
    show: false,
    type: "success",
    title: "",
    text: "",
  });

  const closeToast = () => {
    setToast({ show: false, type: "success", title: "", text: "" });
  };

  const checkIntegrationsLoading = useCallback(async () => {
    for (const account of accountsLoading) {
      const { identifier } = account;
      await getSourceInstanceData(tenant, identifier)
        .then((resp) => {
          const { data } = resp;
          if (data) {
            const { account_descriptor, source_stage } = data;

            if (source_stage === REGULAR) {
              const toastData = {
                type: "success",
                title: "Sincronización finalizada",
                text: `La sincronización de la cuenta ${account_descriptor} ha finalizado, para ver tus datos en la aplicación recargue la página.`,
              };
              enqueueSnackbar("", {
                variant: "snackbar",
                ...toastData,
              });
              setAccountsLoading((prevState) => {
                const indx = prevState.findIndex(
                  (acc) => acc.identifier === identifier
                );
                const newState = [
                  ...prevState.slice(0, indx),
                  ...prevState.slice(indx + 1),
                ];
                return newState;
              });
            }
          }
        })
        .catch((err) => printKodkodLog(err));
    }
    reloadIntegrationData(tenant);
  }, [tenant, accountsLoading, enqueueSnackbar, reloadIntegrationData]);

  useEffect(() => {
    if (sourcesLoading.length && tenant) {
      const getTenantStatus = setInterval(() => {
        checkIntegrationsLoading();
      }, 10000);
      return () => {
        clearInterval(getTenantStatus);
      };
    }
  }, [tenant, sourcesLoading, checkIntegrationsLoading]);

  useEffect(() => {
    if (integratedAccounts) {
      let newIntegrationsLoading = [];
      let newAccountsIntegrationsLoading = [];
      Object.keys(integratedAccounts).forEach((source) => {
        const newSourcesLoading = integratedAccounts[source].accounts.filter(
          (account) =>
            (account.source_stage === HISTORICAL_LOADING ||
              account.source_stage === NEW) &&
            account.source_status === OK
        );

        if (newSourcesLoading.length) {
          const { name } = integratedAccounts[source];
          const existSource = newIntegrationsLoading.indexOf(name);
          if (existSource === -1) {
            newIntegrationsLoading = [...newIntegrationsLoading, name];
          }
          newAccountsIntegrationsLoading = [
            ...newAccountsIntegrationsLoading,
            ...newSourcesLoading,
          ];
        }
      });
      setSourcesLoading(newIntegrationsLoading);
      setAccountsLoading(newAccountsIntegrationsLoading);
    }
  }, [integratedAccounts]);

  useEffect(() => {
    if (sourcesLoading.length) {
      activeBanner(true);
    } else activeBanner(false);
  }, [sourcesLoading]);

  const display =
    tenant_status &&
    tenant_status !== TENANT_STATUS.ONBOARDING &&
    sourcesLoading.length
      ? "block"
      : "none";
  return (
    <>
      <div style={{ display }}>
        <Alert severity="info" sx={{ width: "100%", pl: 5, pr: 5 }}>
          {WELCOME_MESSAGE[0].message.replace(
            "%sourceName%",
            sourcesLoading.join(", ")
          )}
        </Alert>
      </div>
      <Toast show={toast.show} close={closeToast} data={toast} />
    </>
  );
}
