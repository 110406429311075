const styles = {
  root: { display: "flex", alignItems: "center" },
  menu: {
    marginLeft: "3.5em",
    cursor: "pointer",
    fontSize: "0.7em",
    fontWeight: "500",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
  },
  menuList: {
    maxHeight: "350px",
    overflow: "auto",
    width: "15vw",
    minWidth: "230px",
    boxShadow: "0px 8px 16px rgb(55 112 239 / 20%)",
    color: "var(--blue-gray-4)",
  },
  item: {
    overflow: "hidden",
    fontSize: "0.78rem",
    fontWeight: "600",
    lineHeight: "1.21",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    letterSpacing: "0.1px",
    paddingTop: "0.5em",
    paddingBottom: "0.5em",
    //Decoration: "none",
    //color: "#78909C",
  },
  menuItem: {
    minWidth: "0px",
    marginTop: "4px",
    marginBottom: "4px",
  },
  menuItemActive: {
    color: "#3770EF",
    background: "#E2EBFD",
    borderLeft: "4px solid",
  },
  menuIconStyle: {
    fontSize: "1.2em",
    color: "#78909C",
  },
  colapse: { height: "100%", overflow: "auto", maxHeight: 200 },
  dialogTitle: {
    color: "var(--blue-gray-5)",
    display: "flex",
    flexDirection: "column",
  },
  titleSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  subtitle: {
    width: "100%",
    marginTop: "0.5em",
    fontSize: "1rem",
    display: "inline",
  },
  closeIcon: { cursor: "pointer" },
};
export default styles;
